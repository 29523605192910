@use '@ruf/shell' as ruf;
//@import 'node_modules/@ruf/theming/styles/theming/theming';
@import 'node_modules/@ruf/widgets/src/datatable/datatable.theme';

// a convenience mixin that aggregates all of your component theme mixins
@mixin cp-risk-information-theme($theme) {
  $background: map-get($theme, background);
  $foreground: map-get($theme, foreground);
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $emphasis: map-get($theme, emphasis);
  $warn: map-get($theme, warn);

  // TODO(ruf-v512-migration): Signature of this mixin has been changed
// The new signature is: @mixin mat-button-theme($theme: ruf.get-default-basic-css-vars-theme(), $customizations: (), $cls: [fisStyle]);
// Manual intervention is required to fix the signture
// If you want to learn more about the mixin and its parameters, please visit the "button's overview" page on the RUF demo site.
@include ruf.mat-button-theme($theme);
  @include ruf-dynamic-datatable-theme($theme);

  client-portal-cp-risk-information {
    p {
      color: ruf.ruf-color($primary);
    }
  }
}

@mixin cp-risk-information-typography($config) {
  client-portal-cp-risk-information {
    // p {
    //   @include ruf-typography-level-to-styles($config, 'ruf-caption-2');
    // }
    a{
      font-weight: bold;
    &:hover{
      text-decoration: underline;
    }
  }
    h1 {
      @include ruf.ruf-typography-level-to-styles($config, 'title');
    }
  }
}
