//@import 'node_modules/@ruf/theming/styles/theming/theming';
@use '@ruf/shell' as ruf;

// a convenience mixin that aggregates all of your component theme mixins
@mixin cp-main-invoices-page-theme($theme) {
  $background: map-get($theme, background);
  $foreground: map-get($theme, foreground);
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $emphasis: map-get($theme, emphasis);
  $warn: map-get($theme, warn);
  

  client-portal-cp-main-invoices-page {
    p {
      color: ruf.ruf-color($primary);
    }
  }
}

@mixin cp-main-invoices-page-typography($config) {
  client-portal-cp-main-invoices-page {
    p {
      @include ruf.ruf-typography-level-to-styles($config, caption);
    }

    .ruf-invoice-table {
      width: 100%;
    }

    .ruf-inovices-table {
      overflow: hidden;
      display: flex;
      margin: 5px;
      text-align: left;
    }

    .ruf-invoice-table-row td {
      border-bottom-width: 0;
    }

    tr.ruf-invoice-detail-row {
      height: 0;
    }
  }
}
