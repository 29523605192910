/* Importing the prebuilt color theme called "fis-default" */
//@import 'node_modules/@ruf/theming/styles/theming/typography';
@use './main-product-page/components/resources-tools/components/tab-services/components/cp-services-datatable.theme' as cp-services-datatable;
@mixin services-all-theme($theme, $config: null) {
  @include cp-services-datatable.services-datatable-theme($theme);
  @include services-typography($config);
}
@mixin services-typography($theme, $config: ruf-typography-config()) {
  // include your typography mixins below
  //@include tickets datatable typography

  @include cp-services-datatable.services-datatable-typography($config);
  //@@TYPOGRAPHY
}
