//@import 'node_modules/@ruf/theming/styles/theming/theming';
@use "@ruf/shell" as ruf;

// a convenience mixin that aggregates all of your component theme mixins
@mixin cp-main-entity-page-theme($theme) {
  $background: map-get($theme, background);
  $foreground: map-get($theme, foreground);
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $emphasis: map-get($theme, emphasis);
  $warn: map-get($theme, warn);

  client-portal-cp-main-entity-page {
    p {
      color: ruf.ruf-color($primary);
    }
    .view-more-arrow {
      transition: all 0.4s ease-in-out;
      color: ruf.ruf-color($accent);
      cursor: pointer;
    }
    .view-more {
      a {
        color: #015b7e;
      }
      &:hover {
        a {
          color: #285bc5;
        }
        .view-more-arrow {
          transform: translate(5px);
        }
      }
    }
    .mat-mdc-card {
      transition: box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1);
      padding: 16px;
      border-color: transparent;
    }
  }
}

@mixin cp-main-entity-page-typography($config) {
  client-portal-cp-main-entity-page {
    // p {
    //   @include ruf-typography-level-to-styles($config, 'title');
    // }
  }
}
