@use '@ruf/shell/styles/index' as ruf-shell-style-index;
@use './lib-cp-tickets-theme' as lib-cp-tickets;
//@include ticket-all-theme($theme);
hr.ruf-dialog-separator {
  margin: 10px 0 0 0 !important;
}
.mat-mdc-dialog-title {
  margin-bottom: 0 !important;
}

.filter-option > .mat-pseudo-checkbox {
  display: none !important;
}

#product-select-all {
  display: block !important;
  height: 16px;
  width: 16px;
  float: left;
  margin-top: 12px;
}

.select-deselect {
  padding-left: 7px;
}

.mat-mdc-row {
  position: relative;
}

.mat-mdc-row-link {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
}
