@use '@ruf/shell' as ruf;
//@import 'node_modules/@ruf/theming/styles/theming/theming';

// a convenience mixin that aggregates all of your component theme mixins
@mixin dashboard-theme($theme) {
  $background: map-get($theme, background);
  $foreground: map-get($theme, foreground);
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $emphasis: map-get($theme, emphasis);
  $warn: map-get($theme, warn);

  client-portal-cp-main-dashboard {
    background: #f00;

    p {
      color: ruf.ruf-color($primary);
    }
  }
}

@mixin dashboard-typography($config) {
  client-portal-cp-main-dashboard {
    p {
      @include ruf.ruf-typography-level-to-styles($config, 'title');
      /*text-transform: uppercase;
      letter-spacing: 3px;*/
    }
  }
}
