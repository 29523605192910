/* Importing the prebuilt color theme called "fis-default" */
//@import 'node_modules/@ruf/theming/styles/theming/typography';
@use './main-product-page/components/resources-tools/components/tab-reseller-resources/components/cp-reseller-resources-datatable.theme' as cp-reseller-resources-datatable;
@mixin reseller-resources-all-theme($theme, $config: null) {
  @include cp-reseller-resources-datatable.reseller-resources-datatable-theme($theme);
  @include reseller-resources-typography($config);
}
@mixin reseller-resources-typography($theme, $config: ruf-typography-config()) {
  // include your typography mixins below
  //@include tickets datatable typography

  @include cp-reseller-resources-datatable.reseller-resources-datatable-typography($config);
  //@@TYPOGRAPHY
}
