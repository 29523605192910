login {
    .login {
        width: 100%;
        min-height: 100vh !important;
        z-index: 99999;
        top: 0;
        left: 0;
        display: flex;
        flex-direction: row-reverse;
        overflow: auto;

        ::after {
            content: '';
            clear: both;
            display: block;
        }

        .left-login {
            width: 60%;
            float: left;
            padding: 80px 60px 15px 60px;
            background: transparent linear-gradient(315deg, #4BCD3E 0%, #285BC5 100%) 0% 0% no-repeat padding-box;

            h1 {
                text-align: left;
                font-size: 37px !important;
                font-weight: bold;
                font-family: "titillium_webblack" !important;
                letter-spacing: 0px;
                color: #FFFFFF;

                a {
                    display: none;
                }
            }

            p {
                font: normal normal normal 18px/22px Source Sans Pro;
                letter-spacing: 0px;
                color: #FFFFFF;
            }

            .img-app {
                display: none;
            }

            .login-footer {
                color: #fff;
                margin-top: 180px;
                margin-left: -35px;
                margin-right: -35px;
                position: absolute;
                bottom: 0px;
                width: 55%;

                p {
                    float: left;
                    margin: 0;
                    font-size: 11px;
                    font-weight: 500;
                    padding-top: 10px;
                }

                a {
                    float: right;
                    color: #fff;
                    font-family: "titillium Web";
                    font-weight: bold;
                    font-size: 16px;
                }
            }
        }

        .right-login {
            width: 40%;
            float: right;
            background-color: #fff;
            padding: 0px 40px;
            box-sizing: border-box;
            text-align: center;

            div {
                display: inline-flex;
                justify-content: space-between;
                width: 100%;
            }

            img {
                margin-bottom: 60px;
            }

            input {
                background: #FFFFFF 0% 0% no-repeat padding-box;
                border: 1px solid #CCCCCC;
                border-radius: 8px;
                padding: 0 20px;
                margin: 0px;
                margin-bottom: 15px;
                width: 100%;
                line-height: 58px;
                font-size: 22px !important;
                font-weight: 600;
                color: #012834;

                &:focus {
                    border: 1px solid #012834;
                }
            }

            p {
                text-align: right;
                color: #012834;
                font-size: 16px;
                font-weight: bold;
                margin-top: 0;
            }

            button {
                background-color: #4BCD3E;
                width: 100%;
                border-radius: 100px;
                line-height: 48px;
                text-align: center;
                font-size: 18px;
                color: #012834;
                font-weight: bold;
                border: solid #4BCD3E;
                margin-top: 25px;
                outline: none;
                cursor: pointer;
            }

            button:hover {
                background-color: #ffffff00;
                border: solid #4BCD3E;
            }

            span {
                text-align: center;
                cursor: pointer;
                margin-top: 30px;
                color: #012834;
                font-size: 18px;
                font-weight: bold;
                display: block;

                a {
                    color: #012834;
                }
            }
        }
    }

    ::ng-deep .emulate-top-bar,
    ::ng-deep client-portal-cp-header {
        display: none !important;
    }
}

login input.ng-pristine.ng-invalid.ng-touched {
    border: 1px solid #CCCCCC !important;
}

@media only screen and (max-width: 990px) {
    .login .left-login .login-footer {
        color: #fff;
        margin-top: 0;
        margin-left: 0;
        margin-right: 0;
        position: relative;
        bottom: 0px;
        width: 100%;
    }
}

@media only screen and (max-width: 768px) {

    .left-login,
    .right-login {
        width: auto !important;
        float: none !important;
        padding: 60px 20px 55px 20px !important;
        height: auto !important;
    }

    .login {
        position: absolute !important;
        display: block;
        flex-direction: column !important;

        .right-login {
            img {
                width: 132px;
                margin-bottom: 55px;
            }

            div {
                width: 100%;
               // margin-left: 10px;
            }

            button {
                margin-top: 15px;
            }

            span {
                margin-top: 30px;
                font-size: 15px;
            }
        }

        .left-login {
            padding: 25px 20px 20px 20px !important;

            h1 {
                font-size: 26px;

                a {
                    display: block;
                    float: right;
                    color: #fff;
                    font-size: 16px;
                    font-family: "titillium Web";

                    mat-icon {
                        margin-left: 6px;
                    }
                }
            }

            p {
                font-size: 14px;
            }

            .img {
                display: none;
            }

            .img-app {
                display: flex
            }

            .login-footer {
                margin-top: 40px;
                margin-left: 0;
                margin-right: 0;
                position: relative;
                width: 100%;
                display: flex;
                width: 100% !important;
                position: static !important;
                margin: 0px !important;
                flex-direction: column;
            }
        }
    }
}

  .mat-mdc-form-field-error {
    font-size: 18px;
  }

@media only screen and (max-height: 991px) {
    .login {
        background: #fff;

        .right-login {
            align-self: center;
            width: 40%;
            float: right;
            background-color: #fff;
            padding: 0px 40px;
            box-sizing: border-box;
            text-align: center;
        }
    }
}
