@import "switcDarkTheme";
//@import 'node_modules/@ruf/theming/styles/theming/theming';
/*@use 'node_modules/@ruf/ngx-datatable/styles/all-theme';
@use 'node_modules/@ruf/ngx-datatable/styles/_ngx-datatable.structure.scss';*/

@mixin tickets-datatable-theme($theme) {
  $background: map-get($theme, background);
  $foreground: map-get($theme, foreground);
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $emphasis: map-get($theme, emphasis);
  $warn: map-get($theme, warn);

  .mat-mdc-tooltip {
    background: #FFFFFF 0% 0% no-repeat padding-box !important;
    border: 1px solid #DFDFDF;
    font-size: 12px !important;
    color: #00242d !important;
    overflow: visible !important;
    position: relative !important;
    margin-left: 120px !important;

    &::after {
      content: '';
      display: block;
      position: absolute;
      left: -11px;
      bottom: inherit;
      width: 0;
      height: 0;
      /* border: 15px solid transparent; */
      border-bottom-color: #FFff;
      /* transform: rotateX(309deg); */
      transform: rotateZ(45deg);
      top: -5px;
      /* z-index: -1; */
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 5px 20px 6px 0;
      border-color: transparent #fff transparent transparent;
    }
  }

  client-portal-cp-tickets-datatable, client-portal-global-search {
    .mat-mdc-form-text-infix {
      padding-top: 12px !important;
    }
     .mat-mdc-select-arrow {
      @include theme() {
        color: theme-get('mat-arrow') !important;
      }
    }

    .mat-mdc-table[fisStyle] {
      width: 100%;

      .mat-mdc-header-row {
        background: #003b4d;
        padding: 5px 0;
      }

      .mat-mdc-row:hover {
        background-color: #ebebeb !important;
        border-left: 4px solid #4bcd3e;
      }

      .mat-mdc-row {
        border-left: 4px solid transparent;
      }

      .mat-mdc-row:focus {
        outline: none;
      }

      .mat-mdc-header-cell {
        font-family: 'Source Sans Pro', Arial, sans-serif;
        font-size: 13px;
        font-weight: normal;
        color: #fff;
        border-bottom: none;

        .mat-sort-header-arrow {
          color: #fff;
          opacity: 1 !important;
          height: inherit !important;
        }
      }

      .mat-mdc-header-row .mat-mdc-header-cell:hover:not(.mat-mdc-table-sticky) {
        background-color: transparent;
      }
    }

    .mat-mdc-paginator-container {
      justify-content: flex-start;
      padding-left: 0;
    }

    .Download-button-mat {
      cursor: pointer;
      font-weight: bold;
      margin-bottom: 25px;

      mat-icon {
        padding: 10px 0 0 0;
        color: #4bcd3e;
        vertical-align: text-bottom;
      }

      [aria-sort='ascending'] {
        ::ng-deep .mat-sort-header-arrow {
          .mat-sort-header-indicator {
            &::before {
              font: normal normal normal 1.1rem/1 FontAwesome;
              content: '\f0d7';
              position: absolute;
              top: 0.2rem;
            }
          }
        }
      }

      [aria-sort='descending'] {
        ::ng-deep .mat-sort-header-arrow {
          .mat-sort-header-indicator {
            &::before {
              font: normal normal normal 1.1rem/1 FontAwesome;
              content: '\f0d8';
              position: absolute;
              top: -0.9rem;
            }
          }
        }
      }
    }
     .mat-mdc-select-value-text {
      color: #858f94;
    }
      .mat-mdc-paginator-page-size-select {
      width: 40px;
    }
    .mat-form-field-appearance-legacy .mdc-line-ripple {
      display: none;
    }
  }
  // // TODO(ruf-v512-migration): Signature of this mixin has been changed
// The new signature is: @mixin mat-datatable-theme($theme: ruf.get-default-basic-css-vars-theme(), $customizations: (), $cls: [fisStyle]);
// Manual intervention is required to fix the signture
// If you want to learn more about the mixin and its parameters, please visit the "datatable's overview" page on the RUF demo site.
@include mat-datatable-theme($theme);
}

client-portal-global-search .mat-mdc-select-value-text {
  font-size: 16px;
}

@media only screen and (min-width: 768px) {
   .mat-form-field-appearance-legacy .mat-mdc-form-text-infix {
    padding: 0.4375em 0;
    border-radius: 2px;
    top: 0;
    box-shadow: 0px 0px 1px 1px rgb(179 193 199);
    outline: none;
    margin-top: 0;
    padding-left: 5px;
    padding-top: 7px !important;
    height: 35px;
    position: relative;
    padding-bottom: 0;
    border-top: 0;
  }
}

@media only screen and (max-width: 991px) and (min-width: 768px) {
  .mat-form-field-appearance-legacy .mat-mdc-form-text-infix {
    top: 0 !important;
  }
}

@media only screen and (max-width: 991px) {
  .mat-form-field-appearance-legacy .mat-mdc-form-text-infix {
    padding: 0.4375em 0;
    border-radius: 2px;
    top: 18px;
    box-shadow: 0px 0px 1px 1px rgb(179 193 199);
    outline: none;
    margin-top: 0;
    padding-left: 5px;
    padding-top: 7px !important;
    height: 35px;
    position: relative;
    padding-bottom: 0;
    border-top: 0;
  }
}

@media only screen and (max-width: 767px) {
  client-portal-cp-tickets-datatable, client-portal-global-search {
    .entire-mat-page-btn {
      position: relative;
    }
    .paginator-rows {
      margin-bottom: 20px;
    }
    .search-matpaginator-main {
      width: calc(100% - 85px);
      margin: 0;
      float: left;
      display: inline-block;
    }
    .search-matpaginator-main .mat-mdc-paginator-page-size-select {
      width: 36px;
    }

    .mat-mdc-paginator-outer-container {
      width: 100%;
    }

    .gotojump {
      right: 56px;
      top: 7px;
    }
    .gotojump .mat-mdc-text-field-wrapper {
      width: 45px;
      margin-left: 24px !important;
      margin-top: 16px !important;
      margin-right: 0 !important;
    }

    .search-matpaginator-main .mat-mdc-paginator-page-size {
      width: 35%;
      margin: 0;
      padding-right: 15px;
    }
    .search-matpaginator-main .mat-mdc-paginator-range-actions {
      width: 65%;
      padding-left: 23px;
      margin-top: 10px;
    }
    .mat-mdc-paginator[fisStyle] .mat-mdc-paginator-navigation-previous {
      left: 10px;
      top: 5px;
    }
    .mat-mdc-paginator[fisStyle] .mat-mdc-paginator-navigation-next {
      left: 66px;
      top: 5px;
    }
  }
}
@media only screen and (min-width: 768px) {
  client-portal-cp-tickets-datatable, client-portal-global-search {
    .mat-mdc-paginator .mat-mdc-paginator-navigation-next {
      right: -60px;
    }
    .gotojump {
      width: 44px;
      margin-left: -40px;
      // margin-top: 14px;
    }

    .mat-mdc-paginator-range-actions {
      //margin-top: -18px;
    }
    .filter-mattable {
      margin-bottom: -15px;
    }
    .entire-mat-page-btn {
      margin-top: 20px;
    }
  }

  client-portal-global-search {

    .mat-mdc-paginator-range-actions {
      width: 56%;
      justify-content: right;
      margin-left: auto;
    }

    .mat-mdc-paginator-range-label {
      margin: 0 56% 0 0;
      font-weight: bold;
      flex: auto;
    }

    .mat-mdc-paginator-navigation-first {
      left: -60px;
    }

    .mat-mdc-paginator-navigation-previous {
      left: -130px;
      right: inherit;
    }

    .mat-mdc-paginator-navigation-next {
      right: -50px !important;
    }

    .mat-mdc-paginator-navigation-last {
      right: -60px !important;
    }

    .gotojump {
      width: 44px;
      margin-left: 0;
      position: absolute;
      right: 84px;
      top: 30px;
    }
  }
  client-portal-cp-contact-reporting {
    .mat-mdc-paginator-navigation-next {
      left: 55px !important;
    }

    .mat-mdc-paginator-navigation-last {
      left: 55px !important;
    }
    .mat-mdc-paginator-range-label{
    padding-right: 160px;
  }
  } 
}

@mixin tickets-datatable-typography($config) {
  client-portal-cp-tickets-datatable, client-portal-global-search {
    .mat-mdc-table[fisStyle] {
      mat-row {
        border-bottom: none;
      }
      .mat-mdc-header-row {
        .mat-mdc-header-cell {
          color: #fff;
          border-bottom: none;
        }
      }
    }
  }
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  client-portal-cp-tickets-datatable, client-portal-global-search {
    .mat-mdc-table[fisStyle] .mat-sort-header-arrow{
      top:-10px;
    }
  }
}
