/* Importing the prebuilt color theme called "fis-default" */
@use "@ruf/prebuilt-themes/fis-default" as ruf-prebuilt-themes;
// Importing the RUF shell structural styles
@use '@ruf/shell/styles/index' as ruf-shell-style-index; /* Importing the prebuilt color theme called "fis-default" */

@use "./cp-main-administration/_cp-main-administration.theme" as cp-main-administration;

@use "./cp-download-logs/_cp-download-logs.theme" as cp-download-logs;
@use "./cp-subscription-center/_cp-subscription-center.theme" as cp-subscription-center;
@use "./cp-create-update-contact/_cp-create-update-contact.theme" as cp-create-update-contact;
@use "./cp-contact-us/_cp-contact-us.theme" as cp-contact-us;
@use "./cp-success-dialog/_cp-success-dialog.theme" as cp-success-dialog;
@use "./cp-dialog-modals/_cp-dialog-modals.theme" as cp-dialog-modals;
@use "./cp-event-maintainence/_cp-event-maintainence.theme" as cp-event-maintainence;
@use "./cp-create-edit-event/_cp-create-edit-event.theme" as cp-create-edit-event;
@use "./cp-remove-modals/_cp-remove-modals.theme" as cp-remove-modals;
@use "./cp-dialouge-box/_cp-dialouge-box.theme" as cp-dialouge-box;
@use "./cp-invoice-notification-page/_cp-invoice-notification-page.theme" as cp-invoice-notification-page;
@use "./invoice-dialouge-box/_invoice-dialouge-box.theme" as invoice-dialouge-box;
@use "./cp-main-user-onboarding-page/_cp-main-user-onboarding-page.theme" as cp-main-user-onboarding-page;
@use "./cp-user-onboarding-tab/_cp-user-onboarding-tab.theme" as cp-user-onboarding-tab;
@use "./cp-onboarding-status-tab/_cp-onboarding-status-tab.theme" as cp-onboarding-status-tab;
@use "./cp-user-onboarding-dialouge-box/_cp-user-onboarding-dialouge-box.theme" as cp-user-onboarding-dialouge-box;
@use "./cp-success-modal/_cp-success-modal.theme" as cp-success-modal;
@use "./cp-compliance-reporting/_cp-compliance-reporting.theme" as cp-compliance-reporting;
@use "./cp-add-edit-compliance-reporting/_cp-add-edit-compliance-reporting.theme" as cp-add-edit-compliance-reporting;
@use "./cp-delete-dailog/_cp-delete-dailog.theme" as cp-delete-dailog;
@use "./cp-report-administration-card/_cp-report-administration-card.theme" as cp-report-administration-card;
@use "./cp-product-settings/_cp-product-settings.theme" as cp-product-settings;
@use "./cp-reports-rules-administration/_cp-reports-rules-administration.theme" as cp-reports-rules-administration;
@use "./cp-product-settings-dialogue/_cp-product-settings-dialogue.theme" as cp-product-settings-dialogue;
@use "./cp-create-edit-report-admin-card/_cp-create-edit-report-admin-card.theme" as cp-create-edit-report-admin-card;
@use "./cp-rule-dailog/_cp-rule-dailog.theme" as cp-rule-dailog;
@use "./cp-report-admin-card-dialouge/_cp-report-admin-card-dialouge.theme" as cp-report-admin-card-dialouge;
@use "./cp-dialogue-box-tic-tac-toe-useronboarding/_cp-dialogue-box-tic-tac-toe-useronboarding.theme" as cp-dialogue-box-tic-tac-toe-useronboarding;
@use "./cp-create-edit-report-rule/_cp-create-edit-report-rule.theme" as cp-create-edit-report-rule;
@use "./cp-compliance-success/_cp-compliance-success.theme" as cp-compliance-success;

@import "node_modules/@ruf/widgets/src/ruf-upload/file-upload/file-upload.theme";

//@import 'node_modules/@ruf/material/src/card/card.theme';
/*@import "node_modules/@ruf/theming/styles/theming/typography";*/

@mixin administration-app-all-theme($theme, $config: null) {
  @include administration-page-theme($theme, $config);
  @include administration-page-typography($theme, $config);
}

@mixin administration-page-theme($theme, $config: null) {
  $background: map-get($theme, background);
  $foreground: map-get($theme, foreground);
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $emphasis: map-get($theme, emphasis);
  $warn: map-get($theme, warn);
  $error: map-get($theme, error);

  // @include ruf-card-theme($theme);
  @include ruf-file-upload-theme($theme);
  @include cp-main-administration.cp-main-administration-theme($theme);
  @include cp-download-logs.cp-download-logs-theme($theme);
  @include cp-subscription-center.cp-subscription-center-theme($theme);
  @include cp-contact-us.cp-contact-us-theme($theme);
  @include cp-create-update-contact.cp-create-update-contact-theme($theme);
  @include cp-success-dialog.cp-success-dialog-theme($theme);
  @include cp-dialog-modals.cp-dialog-modals-theme($theme);
  @include cp-event-maintainence.cp-event-maintainence-theme($theme);
  @include cp-create-edit-event.cp-create-edit-event-theme($theme);
  @include cp-remove-modals.cp-remove-modals-theme($theme);
  @include cp-dialouge-box.cp-dialouge-box-theme($theme);
  @include cp-invoice-notification-page.cp-invoice-notification-page-theme($theme);
  @include invoice-dialouge-box.invoice-dialouge-box-theme($theme);
  @include cp-main-user-onboarding-page.cp-main-user-onboarding-page-theme($theme);
  @include cp-user-onboarding-tab.cp-user-onboarding-tab-theme($theme);
  @include cp-onboarding-status-tab.cp-onboarding-status-tab-theme($theme);
  @include cp-user-onboarding-dialouge-box.cp-user-onboarding-dialouge-box-theme($theme);
  @include cp-success-modal.cp-success-modal-theme($theme);
  @include cp-compliance-reporting.cp-compliance-reporting-theme($theme);
  @include cp-add-edit-compliance-reporting.cp-add-edit-compliance-reporting-theme($theme);
  @include cp-delete-dailog.cp-delete-dailog-theme($theme);
  @include cp-report-administration-card.cp-report-administration-card-theme($theme);
  @include cp-product-settings.cp-product-settings-theme($theme);
  @include cp-reports-rules-administration.cp-reports-rules-administration-theme($theme);
  @include cp-product-settings-dialogue.cp-product-settings-dialogue-theme($theme);
  @include cp-create-edit-report-admin-card.cp-create-edit-report-admin-card-theme($theme);
  @include cp-rule-dailog.cp-rule-dailog-theme($theme);
  @include cp-report-admin-card-dialouge.cp-report-admin-card-dialouge-theme($theme);
  @include cp-dialogue-box-tic-tac-toe-useronboarding.cp-dialogue-box-tic-tac-toe-useronboarding-theme($theme);
  @include cp-create-edit-report-rule.cp-create-edit-report-rule-theme($theme);
  @include cp-compliance-success.cp-compliance-success-theme($theme);
}

@mixin administration-page-typography($config: null) {
  @include cp-main-administration.cp-main-administration-typography($config);
  @include cp-download-logs.cp-download-logs-typography($config);
  @include cp-subscription-center.cp-subscription-center-typography($config);
  @include cp-contact-us.cp-contact-us-typography($config);
  @include cp-create-update-contact.cp-create-update-contact-typography($config);
  @include cp-success-dialog.cp-success-dialog-typography($config);
  @include cp-dialog-modals.cp-dialog-modals-typography($config);
  @include cp-event-maintainence.cp-event-maintainence-typography($config);
  @include cp-create-edit-event.cp-create-edit-event-typography($config);
  @include cp-remove-modals.cp-remove-modals-typography($config);
  @include cp-dialouge-box.cp-dialouge-box-typography($config);
  @include cp-invoice-notification-page.cp-invoice-notification-page-typography($config);
  @include invoice-dialouge-box.invoice-dialouge-box-typography($config);
  @include cp-main-user-onboarding-page.cp-main-user-onboarding-page-typography($config);
  @include cp-user-onboarding-tab.cp-user-onboarding-tab-typography($config);
  @include cp-onboarding-status-tab.cp-onboarding-status-tab-typography($config);
  @include cp-user-onboarding-dialouge-box.cp-user-onboarding-dialouge-box-typography($config);
  @include cp-success-modal.cp-success-modal-typography($config);
  @include cp-compliance-reporting.cp-compliance-reporting-typography($config);
  @include cp-add-edit-compliance-reporting.cp-add-edit-compliance-reporting-typography($config);
  @include cp-delete-dailog.cp-delete-dailog-typography($config);
  @include cp-report-administration-card.cp-report-administration-card-typography($config);
  @include cp-product-settings.cp-product-settings-typography($config);
  @include cp-reports-rules-administration.cp-reports-rules-administration-typography($config);
  @include cp-product-settings-dialogue.cp-product-settings-dialogue-typography($config);
  @include cp-create-edit-report-admin-card.cp-create-edit-report-admin-card-typography($config);
  @include cp-rule-dailog.cp-rule-dailog-typography($config);
  @include cp-report-admin-card-dialouge.cp-report-admin-card-dialouge-typography($config);
  @include cp-dialogue-box-tic-tac-toe-useronboarding.cp-dialogue-box-tic-tac-toe-useronboarding-typography($config);
  @include cp-create-edit-report-rule.cp-create-edit-report-rule-typography($config);
  @include cp-compliance-success.cp-compliance-success-typography($config);
}
/*cp-main-bulletins-landing-page,*/
cp-main-bulletins-landing-page{padding: 10px 0 30px;}
cp-main-view-bulletins, cp-admin-bulletin-details, cp-admin-bulletin-email-logs, cp-main-bulletin-opt-out-report {
  padding: 10px 30px 30px;
}
