// /* Importing the prebuilt color theme called "fis-default" */
// @import '~@ruf/prebuilt-themes/fis-default';
// // Importing the RUF shell structural styles
// @import '~@ruf/shell/styles/index'; /* Importing the prebuilt color theme called "fis-default" */
@use '@ruf/shell' as ruf;
// @import 'node_modules/@ruf/theming/styles/theming/theming';
// //@import 'node_modules/@ruf/material/src/card/card.theme';
// @import 'node_modules/@ruf/theming/styles/theming/typography';

@use './cp-main-reports/_cp-main-reports.theme' as cp-main-reports;
//   @include cp-main-reports-theme($theme);
//   @include cp-main-reports-typography($config);
@use './cp-reports-header/_cp-reports-header.theme' as cp-reports-header;
//   @include cp-reports-header-theme($theme);
//   @include cp-reports-header-typography($config);

@mixin reports-app-all-theme($theme, $config: null) {
  @include reports-page-theme($theme, $config);
  @include reports-page-typography($theme, $config);
}

@mixin reports-page-theme($theme, $config: null) {
  $background: map-get($theme, background);
  $foreground: map-get($theme, foreground);
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $emphasis: map-get($theme, emphasis);
  $warn: map-get($theme, warn);

  @include cp-main-reports.cp-main-reports-theme($theme);
  @include cp-reports-header.cp-reports-header-theme($theme);
}

@mixin reports-page-typography($config: null) {
  .ruf-reports-title {
    font-family: ruf.ruf-font-family($config, title);
    font-size: ruf.ruf-font-size($config, title);
    font-weight: ruf.ruf-font-weight($config, title);
  }

  .ruf-reports-subtitle {
    font-family: ruf.ruf-font-family($config, title);
    font-size: ruf.ruf-font-size($config, caption);
  }

  @include cp-main-reports.cp-main-reports-typography($config);
  @include cp-reports-header.cp-reports-header-typography($config);
}
