@use '@ruf/shell' as ruf;
//@import 'node_modules/@ruf/theming/styles/theming/theming';
// a convenience mixin that aggregates all of your component theme mixins
@mixin cp-side-menu-bar-theme($theme) {
  $background: map-get($theme, background);
  $foreground: map-get($theme, foreground);
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $emphasis: map-get($theme, emphasis);
  $warn: map-get($theme, warn);
  // $bgColor: map-get($theme, bgColor); 

  client-portal-cp-side-menu-bar {
    p {
      color: ruf.ruf-color($primary);
    }
    .container-bg-color{
      background-color: rgba(0, 0, 0, 0.1);
    }
  }
}

@mixin cp-side-menu-bar-typography($config) {
  client-portal-cp-side-menu-bar {
    p {
      @include ruf.ruf-typography-level-to-styles($config, 'title');
    }
  }
}
