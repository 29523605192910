/* Importing the prebuilt color theme called "fis-default" */
//@import 'node_modules/@ruf/theming/styles/theming/typography';
@use './main-product-page/components/resources-tools/components/tab-strategy/components/cp-strategy-datatable.theme' as cp-strategy-datatable;
@mixin strategy-all-theme($theme, $config: null) {
  @include cp-strategy-datatable.strategy-datatable-theme($theme);
  @include strategy-typography($config);
}
@mixin strategy-typography($theme, $config: ruf-typography-config()) {
  // include your typography mixins below
  //@include tickets datatable typography

  @include cp-strategy-datatable.strategy-datatable-typography($config);
  //@@TYPOGRAPHY
}
