//@import 'node_modules/@ruf/theming/styles/theming/theming';
@use "@ruf/shell" as ruf;

// a convenience mixin that aggregates all of your component theme mixins
@mixin cp-event-maintainence-theme($theme) {
  $background: map-get($theme, background);
  $foreground: map-get($theme, foreground);
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $emphasis: map-get($theme, emphasis);
  $warn: map-get($theme, warn);
  $error: map-get($theme, error);

  client-portal-cp-event-maintainence {
    p {
      color: ruf.ruf-color($primary);
      font-weight: 500 !important;
    }
    .star-color {
      color: ruf.ruf-color($error);
    }
  }
}

@mixin cp-event-maintainence-typography($config) {
  client-portal-cp-event-maintainence {
    p {
      @include ruf.ruf-typography-level-to-styles($config, "title");
    }
  }
}
