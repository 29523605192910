/* Importing the prebuilt color theme called "fis-default" */
@use "@ruf/prebuilt-themes/fis-default" as ruf-prebuilt-themes;
// Importing the RUF shell structural styles
@use '@ruf/shell/styles/index' as ruf-shell-style-index; /* Importing the prebuilt color theme called "fis-default" */
@use "./cp-main-user-onboarding-page/cp-main-user-onboarding-theme" as main-user-onboarding;
@use "./cp-main-entity-page/cp-main-entity-page.theme" as cp-main-entity-page;

//@import 'node_modules/@ruf/material/src/card/card.theme';
/*@import "node_modules/@ruf/theming/styles/theming/typography";*/

@mixin entity-app-all-theme($theme, $config: null) {
  @include entity-page-theme($theme, $config);
  @include entity-page-typography($theme, $config);
}

@mixin entity-page-theme($theme, $config: null) {
  $background: map-get($theme, background);
  $foreground: map-get($theme, foreground);
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $emphasis: map-get($theme, emphasis);
  $warn: map-get($theme, warn);

  //@include ruf-card-theme($theme);

  @include cp-main-entity-page.cp-main-entity-page-theme($theme);
}

@mixin entity-page-typography($config: null) {
  @include cp-main-entity-page.cp-main-entity-page-typography($config);
}
