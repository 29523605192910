@use '@ruf/shell' as ruf;
//@import 'node_modules/@ruf/theming/styles/theming/theming';

// a convenience mixin that aggregates all of your component theme mixins
@mixin cp-submit-an-idea-theme($theme) {
  $background: map-get($theme, background);
  $foreground: map-get($theme, foreground);
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $emphasis: map-get($theme, emphasis);
  $warn: map-get($theme, warn);
  $error: map-get($theme, error);

  // TODO(ruf-v512-migration): Signature of this mixin has been changed
// The new signature is: @mixin mat-button-theme($theme: ruf.get-default-basic-css-vars-theme(), $customizations: (), $cls: [fisStyle]);
// Manual intervention is required to fix the signture
// If you want to learn more about the mixin and its parameters, please visit the "button's overview" page on the RUF demo site.
@include ruf.mat-button-theme($theme);

  client-portal-cp-submit-an-idea {
    p {
      color: ruf.ruf-color($primary);
    }
   .ruf-heading{
      text-align: center;
    }
    .star-color{
      color: ruf.ruf-color($error);
  }
  }
}

@mixin cp-submit-an-idea-typography($config) {
  client-portal-cp-submit-an-idea {
    p {
      @include ruf.ruf-typography-level-to-styles($config, 'caption');
    }
    h1 {
      @include ruf.ruf-typography-level-to-styles($config, 'title');
    }
  }
}
