/* Importing the prebuilt color theme called "fis-default" */
@use '@ruf/shell' as ruf;
@use '@ruf/prebuilt-themes/fis-default' as ruf-prebuilt-themes;
// Importing the RUF shell structural styles
@use '@ruf/shell/styles/index' as ruf-shell-style-index; /* Importing the prebuilt color theme called "fis-default" */

//@import 'node_modules/@ruf/theming/styles/theming/theming';
//@import 'node_modules/@ruf/material/src/card/card.theme';
@use './cp-main-bulletins-page/_cp-main-bulletins-page.theme' as cp-main-bulletins-page;
@use './cp-bulletins-view/_cp-bulletins-view.theme' as cp-bulletins-view;
@use './cp-pinned-dialog/_cp-pinned-dialog.theme' as cp-pinned-dialog;

/*@import 'node_modules/@ruf/theming/styles/theming/typography';*/



@mixin bulletins-app-all-theme($theme, $config: null) {
  @include bulletins-page-theme($theme, $config);
  @include bulletins-page-typography($theme, $config);
}

@mixin bulletins-page-theme($theme, $config: null) {
  $background: map-get($theme, background);
  $foreground: map-get($theme, foreground);
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $emphasis: map-get($theme, emphasis);
  $warn: map-get($theme, warn);
  $error: map-get($theme, error);

  .error {
    color: ruf.ruf-color($error);
  }

  .contrast {
    color: lightgray;
  }

  @include cp-bulletins-view.cp-bulletins-view-theme($theme);
  @include cp-main-bulletins-page.cp-main-bulletins-page-theme($theme);
  @include cp-pinned-dialog.cp-pinned-dialog-theme($theme);
}

@mixin bulletins-page-typography($config: null) {
  .ruf-bulletins-title {
    font-family: ruf.ruf-font-family($config, title);
    font-size: ruf.ruf-font-size($config, title);
    font-weight: ruf.ruf-font-weight($config, title);
  }

  .ruf-bulletins-subtitle {
    font-family: ruf.ruf-font-family($config, title);
    font-size: ruf.ruf-font-size($config, caption);
  }

  @include cp-main-bulletins-page.cp-main-bulletins-page-typography($config);
  @include cp-bulletins-view.cp-bulletins-view-typography($config);
  @include cp-pinned-dialog.cp-pinned-dialog-typography($config);
}
