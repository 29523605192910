/* Importing the prebuilt color theme called "fis-default" */
@use "@ruf/prebuilt-themes/fis-default" as ruf-prebuilt-themes;
// Importing the RUF shell structural styles
@use '@ruf/shell/styles/index' as ruf-shell-style-index;


.ebt-ticket-details {
  .breadcrumb {
    position: absolute;
    top: 10px;
  }
}

.mat-mdc-form-field {
  .mat-mdc-floating-label span.mat-mdc-form-field-required-marker {
    color: #ff1f3e !important;
    margin-left: 2px;
  }
  &.conditionalField {
     .mat-mdc-floating-label span.mat-mdc-form-field-required-marker {
      display: none;
    }
    &.requiredField {
        .mat-mdc-floating-label span.mat-mdc-form-field-required-marker {
        display: inline-block;
      }
    }
  }

}

.mat-mdc-table[fisStyle] {
  width: 100%;
  .mat-mdc-header-row {
    background: #003b4d;
    padding: 5px 0;
  }
  .mat-mdc-row:hover {
    background-color: #ebebeb !important;
    border-left: 4px solid #4bcd3e;
  }
  .mat-mdc-row {
    border-left: 4px solid transparent;
  }
  .mat-mdc-row:focus {
    outline: none;
  }

  .mat-mdc-header-cell {
    font-family: 'Source Sans Pro', Arial, sans-serif;
    font-size: 13px;
    font-weight: normal;
    color: #fff;
    border-bottom: none;

    .mat-sort-header-arrow {
      color: #fff;
      opacity: 1 !important;

      height:inherit !important;
    }
  }

  .mat-mdc-header-row .mat-mdc-header-cell:hover:not(.mat-mdc-table-sticky) {
    background-color: transparent;
  }
}
