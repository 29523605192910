//@import 'node_modules/@ruf/theming/styles/theming/theming';
@use '@ruf/shell' as ruf;
// a convenience mixin that aggregates all of your component theme mixins
@mixin not-found-theme($theme) {
  $background: map-get($theme, background);
  $foreground: map-get($theme, foreground);
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $emphasis: map-get($theme, emphasis);
  $warn: map-get($theme, warn);

  client-portal-not-found {
    p {
      color: ruf.ruf-color($primary);
    }
  }
}

@mixin not-found-typography($config) {
  client-portal-not-found {
    p {
      @include ruf.ruf-typography-level-to-styles($config, 'title');
      text-transform: uppercase;
      letter-spacing: 3px;
    }
  }
}
