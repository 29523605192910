/* Importing the prebuilt color theme called "fis-default" */
@use "@ruf/prebuilt-themes/fis-default" as ruf-prebuilt-themes;
// Importing the RUF shell structural styles
@use '@ruf/shell/styles/index' as ruf-shell-style-index;

.mat-mdc-form-field-error {
  color: #ff1f3e !important;
  font-size: 12px;
}

.status-bar-contact {
  /* ========== Status Bar Info ============= */

  .ruf-statusbar:not(.ruf-statusbar-rtl).ruf-info {
    background-color: var(--info-50, #e7f9fd);

    border-color: var(--info, #3bcff0);

    border-left-color: var(--info, #3bcff0);

    color: var(--info-50-contrast, #012834);
  }

  .ruf-statusbar:not(.ruf-statusbar-rtl).ruf-info .ruf-statusbar-detail .ruf-statusbar-desc-content {
    background-color: transparent;
  }

  .mat-icon.mat-info {
    color: #3bcff0;
  }

  /* ========== Status Bar Emphasis ============= */

  .ruf-statusbar:not(.ruf-statusbar-rtl).ruf-emphasis {
    background-color: var(--emphasis-50, #fff8e9);
    border-color: var(--emphasis, #ffc845);
    border-left-color: #ffc845;
    color: var(--emphasis-50-contrast, #012834);
  }
  .ruf-statusbar:not(.ruf-statusbar-rtl).ruf-emphasis .ruf-statusbar-detail .ruf-statusbar-desc-content {
    background-color: transparent;
  }

  .mat-icon.mat-emphasis {
    color: #ffc845;
  }

  .ruf-statusbar:not(.ruf-statusbar-rtl).ruf-emphasis .ruf-statusbar-close:hover,
  .ruf-statusbar:not(.ruf-statusbar-rtl).ruf-emphasis .ruf-statusbar-expand:hover,
  .ruf-statusbar:not(.ruf-statusbar-rtl).ruf-emphasis .ruf-statusbar-close.cdk-keyboard-focused,
  .ruf-statusbar:not(.ruf-statusbar-rtl).ruf-emphasis .ruf-statusbar-expand.cdk-keyboard-focused {
    background-color: var(--emphasis-100, #ffefc7);
  }

  .ruf-statusbar:not(.ruf-statusbar-rtl) {
    padding-left: 8px;
    padding-right: 0;
  }

  .ruf-statusbar-header {
    padding-left: 0;
    padding-right: 0;
  }

  .ruf-statusbar-icon {
    position: absolute;
    .ruf-statusbar-icon-content {
      .mat-icon {
        position: absolute;
      }
    }
  }

  .ruf-statusbar-content {
    .ruf-statusbar-header-text {
      padding-left: 2rem;
    }
  }
}

.ticket-details {
  .breadcrumb {
    position: absolute;
    top: 10px;
  }
}

.mat-mdc-form-field {
  .mat-mdc-floating-label span.mat-mdc-form-field-required-marker {
    color: #ff1f3e !important;
    margin-left: 2px;
  }
  &.conditionalField {
    .mat-mdc-floating-label span.mat-mdc-form-field-required-marker {
      display: none;
    }
    &.requiredField {
      .mat-mdc-floating-label span.mat-mdc-form-field-required-marker {
        display: inline-block;
      }
    }
  }
}

.mat-mdc-table[fisStyle] {
  width: 100%;

  .mat-mdc-header-row {
    background: #003b4d;
    padding: 5px 0;
  }

  .mat-mdc-row:hover {
    background-color: #ebebeb !important;
    border-left: 4px solid #4bcd3e;
  }

  .mat-mdc-row {
    border-left: 4px solid transparent;
  }

  .mat-mdc-row:focus {
    outline: none;
  }

  .mat-mdc-header-cell {
    font-family: "Source Sans Pro", Arial, sans-serif;
    font-size: 13px;
    font-weight: normal;
    color: #fff;
    border-bottom: none;
    background-color: #003b4d !important;

    .mat-sort-header-arrow {
      color: #fff;
      opacity: 1 !important;
      height: inherit !important;
    }
  }

  .mat-mdc-header-row .mat-mdc-header-cell:hover:not(.mat-mdc-table-sticky) {
    background-color: #003b4d !important;
  }
}

/* =================== Dialog Style ================== */

.cdk-overlay-pane mat-dialog-container.mat-mdc-dialog-container .ruf-dialog-panel.fis-override .ruf-dialog-container {
  // padding: 0 !important;
  .ruf-dialog-content {
    margin: 0 !important;
    max-height: 100% !important;
    padding: 0 !important;
  }
  .ruf-dialog-separator {
    display: none;
  }
}

/* TODO(mdc-migration): The following rule targets internal classes of dialog that may no longer apply for the MDC version. */
mat-dialog-container.mat-mdc-dialog-container .ruf-dialog-separator-enabled .ruf-dialog-header {
  min-height: 0 !important;
}

.ruf-dialog-container .ruf-dialog-header.mat-mdc-dialog-title .ruf-dialog-header-bar {
  .dialog-header {
    font-size: 24px;
    border-bottom: 1px solid #b3c1c7;
    padding-bottom: 20px;
    margin-bottom: 12px;
    width: 100% !important;
  }
  .mat-mdc-icon-button {
    top: -2px !important;
    right: 0.2rem !important;
    z-index: 99;
    mat-icon {
      color: #4bcd3e;
    }
    &:hover, &:focus {
      background-color: transparent;
    }
  }
}

ruf-dialog-content {
  form {
    margin-top: 15px;
    .mat-mdc-form-field-flex{width: 99%; margin: 0 1px;}
  }
  .botm-btns {
    border-top: 1px solid #b3c1c7;
    padding-top: 12px;
    margin-top: 12px;
    .mat-mdc-button-base {
      margin-right: 8px;
    }
  }
}

/*======================== Custom Changes =========================*/
/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
.select_label.mat-form-field-appearance-legacy .mdc-line-ripple{display:none;}
/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
ruf-dialog-content .mat-mdc-form-field-type-mat-select .mat-mdc-floating-label{margin-left: 10px;}
/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
ruf-dialog-content .mat-form-field-appearance-legacy.mat-form-field-can-float.mat-form-field-should-float .mat-mdc-floating-label{transform:translateY(-1.38125em) translateX(-10px) scale(0.75) perspective(100px) translateZ(0.0022px);}

/* =================== Table Style ================== */

.mat-mdc-table.ruf-multiple-file-table[fisStyle] .mat-mdc-header-row .mat-mdc-header-cell {
  border-bottom-color: transparent !important;
  color: #fff !important;
}

.ruf-multiple-file-table {
  .mat-mdc-header-row {
    .mat-mdc-header-cell {
      color: #fff;
    }
  }
}

.mat-form-field-appearance-outline:not(.mat-form-field-appearance-legacy)[boxStyle] .mdc-notched-outline,
.mat-form-field-appearance-outline:not(.mat-form-field-appearance-legacy)[fisStyle] .mdc-notched-outline {
  // height: 2.25rem !important;
  min-height: 2.25rem;
}
.contact-details {
  .custom-form-field {
    .mat-mdc-text-field-wrapper {
      margin: 0 !important;
      padding: 8px 0 0 !important;
      .mat-mdc-form-field-flex {
        .mat-mdc-form-text-infix {
          padding: 12px 0 8px;
        }
      }
    }
  }
}

/* =================== Custom Mat Expansion Panel Style ================== */

.latest-activity-box {
  .mat-expansion-panel[fisStyle] {
    .mat-expansion-panel-header {
      .mat-content {
        font-size: 24px;
      }
      .mat-expansion-indicator {
        position: absolute;
        right: 0;
      }
    }
  }
}

client-portal-cp-tickets-details {
  .custom-tab-group[fisStyle] {
    .mat-ink-bar {
      height: 4px;
      background-color: #4bcd3e;
    }
    .mat-mdc-tab {
      padding: 0 20px !important;
      font-weight: 600;
    }

    p,
    label {
      font-weight: normal;
    }
    /* =========== Code to disable overflow ================== */
    .mat-mdc-tab-body-wrapper {
      overflow: visible;
      width: 100%;
      .mat-mdc-tab-body.mat-mdc-tab-body-active {
        overflow-x: visible;
        overflow-y: visible;
        width: inherit;
        .mat-mdc-tab-body-content {
          overflow: visible;
        }
      }
    }
  }
  .mat-mdc-tab-list {
    border-bottom: 1px solid #b3c1c7;
  }

  @media (max-width: 767px) {
    .ruf-margin-large {
      margin: 0 !important;
    }

    .ruf-padding-large {
      padding: 0 !important;
    }
  }
}

.select-all {
  .mdc-checkbox__checkmark {
    border-color: #012834;
    border-width: 0.0625rem;
  }
 
  .mat-mdc-checkbox-checked.mat-primary .mdc-checkbox__background,
  .mat-primary .mat-pseudo-checkbox-checked {
  background-color: #4bcd3e !important;
  }
}

.custom-select {
  mat-pseudo-checkbox.mat-pseudo-checkbox:not(.mat-pseudo-checkbox-checked) {
    // color: #4bcd3e;
  }
}
