@use '@ruf/shell' as ruf;
//@import 'node_modules/@ruf/theming/styles/theming/theming';

// a convenience mixin that aggregates all of your component theme mixins
@mixin cp-webinar-series-theme($theme) {
  $background: map-get($theme, background);
  $foreground: map-get($theme, foreground);
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $emphasis: map-get($theme, emphasis);
  $warn: map-get($theme, warn);

  client-portal-cp-webinar-series {
    p {
      color: ruf.ruf-color($primary);
    }
  }
}

@mixin cp-webinar-series-typography($config) {
  client-portal-cp-webinar-series {
    // p {
    //   @include ruf-typography-level-to-styles($config, 'title');
    // }
    a{
      font-weight: bold;
      &:hover{
        text-decoration: underline;
      }
    }
    h1 {
      @include ruf.ruf-typography-level-to-styles($config, 'title');
    }
  }
}
