/* Importing the prebuilt color theme called "fis-default" */
//@import 'node_modules/@ruf/theming/styles/theming/typography';
@use './main-product-page/components/resources-tools/components/tab-forums/components/cp-forum-datatable.theme' as cp-forum-datatable;
@mixin forum-all-theme($theme, $config: null) {
  @include cp-forum-datatable.forum-datatable-theme($theme);
  @include forum-typography($config);
}
@mixin forum-typography($theme, $config: ruf-typography-config()) {
  // include your typography mixins below
  //@include tickets datatable typography

  @include cp-forum-datatable.forum-datatable-typography($config);
  //@@TYPOGRAPHY
}
