/* Importing the prebuilt color theme called "fis-default" */
@use '@ruf/prebuilt-themes/fis-default' as ruf-prebuilt-themes;
// Importing the RUF shell structural styles
@use '@ruf/shell/styles/index' as ruf-shell-style-index; /* Importing the prebuilt color theme called "fis-default" */

//@import 'node_modules/@ruf/theming/styles/theming/theming';
//@import 'node_modules/@ruf/material/src/card/card.theme';
/*@import 'node_modules/@ruf/theming/styles/theming/typography';*/

@use './components/forgot-entity-success/forgot-entity-success.component' as forgot-entity-success;
@use './components/forgot-entity/forgot-entity.component' as forgot-entity;
@use './components/trouble-logging/trouble-logging.component' as trouble-logging;
@use './components/login/login.component' as login;

@mixin login-app-all-theme($theme, $config: null) {
  @include login-page-theme($theme, $config);
  @include login-page-typography($theme, $config);
}

@mixin login-page-theme($theme, $config: null) {
  $background: map-get($theme, background);
  $foreground: map-get($theme, foreground);
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $emphasis: map-get($theme, emphasis);
  $warn: map-get($theme, warn);

  //@include ruf-card-theme($theme);
}

@mixin login-page-typography($config: null) {

}
