/* Importing the prebuilt color theme called "fis-default" */
//@import 'node_modules/@ruf/theming/styles/theming/typography';
@use './main-product-page/components/resources-tools/components/tab-downloads/components/cp-download-datatable.theme' as cp-download-datatable;
@mixin download-all-theme($theme, $config: null) {
  @include cp-download-datatable.download-datatable-theme($theme);
  @include download-typography($config);
}
@mixin download-typography($theme, $config: ruf-typography-config()) {
  // include your typography mixins below
  //@include tickets datatable typography

  @include cp-download-datatable.download-datatable-typography($config);
  //@@TYPOGRAPHY
}
