// IMPORTANT - PLEASE DO NOT REMOVE THE SPECIAL COMMENTS STARTING WITH //@@.
@use '@ruf/shell' as ruf;
@use '@ruf/widgets' as ruf-widgets;

// THESE ARE REQUIRED FOR ADDING IMPORTS AND INCLUDES FOR A NEWLY CREATED COMPONENT.
@use './app/dashboard/_dashboard.theme' as dashboard;
@use '../../../libs/cp-community/src/lib/cp-community-theme' as community-page;
@use '../../../libs/cp-vendor-management/src/lib/cp-vendor-management-theme' as vendor-page;
@use '../../../libs/cp-support-page/src/lib/cp-support-page.theme' as support-page;
@use '../../../libs/cp-invoices-page/src/lib/cp-invoices-page-theme' as invoice-page;
@use '../../../libs/cp-bulletins-page/src/lib/cp-bulletins-page-theme' as bulletins-page;
@use '../../../libs/cp-reports-page/src/lib/cp-reports-page-theme' as reports-page;
@use '../../../libs/cp-administration/src/lib/cp-administration-theme' as administration-page;
@use '../../../libs/cp-login-page/src/lib/cp-login-page-theme' as login-page;
@use '../../../libs/cp-entity-page/src/lib/cp-entity-page.theme' as entity-page;
@use '../../../libs/cp-lib-tickets-details/src/lib/cp-lib-tickets-details-theme' as tickets-details;
// @import '../../../libs/cp-community/src/lib/components/cp-view-more/cp-view-more.theme';
@use '../../../libs/cp-shared/src/lib/components/not-found/_not-found.theme' as not-found;
@use './app/components/side-menu/side-menu.theme' as side-menu;
@use '../../../libs/cp-shared/src/lib/components/my-products-panel/my-products-panel.theme' as my-products-panel;

@use 'node_modules/@ruf/widgets/src/avatar/ruf-avatar/ruf-avatar.theme';
@use 'node_modules/@ruf/widgets/src/avatar/ruf-avatar-image/ruf-avatar-image.theme';
@use 'node_modules/@ruf/material/src/slide-toggle/slide-toggle.theme';

/*#######################*/
@use "../../../libs/lib-cp-tickets/src/lib/cp-tickets-comp-theme" as cp-tickets-comp;
//@use "../../../libs/cp-community/src/lib/cp-community-theme";
@use "../../../libs/cp-ebt-tickets-lib/src/lib/_cp-ebt-tickets-lib-theme";
//@use "../../../libs/cp-vendor-management/src/lib/cp-vendor-management-theme";
//@use "../../../libs/cp-support-page/src/lib/cp-support-page.theme";
//@use "../../../libs/cp-lib-tickets-details/src/lib/_cp-lib-tickets-details-theme";

@use "../../../libs/my-products-new/src/lib/my-product-theme" as my-products-new;

/*@import 'node_modules/@ruf/theming/styles/theming/typography'; //not need 
@import 'node_modules/@angular/material/_theming.scss';// not need
*/
/*@import '../../../libs/cp-shared/src/lib/components/not-found/_not-found.theme';*/
/*@import './app/components/side-menu/_side-menu.theme';*/
/*@import '../../../libs/cp-shared/src/lib/components/my-products-panel/_my-products-panel.theme';*/
/*@import 'node_modules/@ruf/widgets/src/list-builder/list-builder.theme';
@import 'node_modules/@ruf/widgets/src/avatar/ruf-avatar/ruf-avatar.theme';
@import 'node_modules/@ruf/widgets/src/avatar/ruf-avatar-image/ruf-avatar-image.theme';
@import 'node_modules/@ruf/material/src/slide-toggle/slide-toggle.theme';*/


//@import '~@zeno/highcharts/theming';
//@@IMPORT

// a convenience mixin that aggregates all of your component theme mixins
@mixin app-all-theme($theme, $config: null) {

  $background: map-get($theme, background);
  $foreground: map-get($theme, foreground);
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $emphasis: map-get($theme, emphasis);
  $warn: map-get($theme, warn);
  $error: map-get($theme, error);

  @include dashboard.dashboard-theme($theme);
  @include not-found.not-found-theme($theme);
  @include side-menu.side-menu-theme($theme);
  @include my-products-new.my-product-theme($theme);
  /*@include side1-menu-theme($theme);*/
  @include my-products-panel.my-products-panel-theme($theme);
  @include ruf-widgets.ruf-list-builder-theme($theme);
  @include cp-ruf-list-builder-theme($theme);
  @include ruf-widgets.ruf-avatar-theme($theme);
  @include ruf-widgets.ruf-avatar-image-theme($theme);
  // // TODO(ruf-v512-migration): Signature of this mixin has been changed
  // The new signature is: @mixin mat-slide-toggle-theme($theme: ruf.get-default-basic-css-vars-theme(), $customizations: (), $cls: [fisStyle]);
  // Manual intervention is required to fix the signture
  // If you want to learn more about the mixin and its parameters, please visit the "slide-toggle's overview" page on the RUF demo site.
  @include ruf.mat-slide-toggle-theme($theme);
  @include community-page.community-page-theme($theme, $config);
  @include vendor-page.vendor-page-theme($theme, $config);
  @include support-page.support-page-theme($theme, $config);
  @include invoice-page.invoice-page-theme($theme, $config);
  @include bulletins-page.bulletins-page-theme($theme, $config);
  @include reports-page.reports-page-theme($theme, $config);
  @include administration-page.administration-page-theme($theme, $config);
  @include login-page.login-page-theme($theme, $config);
  @include entity-page.entity-page-theme($theme, $config);
  // @include view-more-theme($theme, $config);
  //@include zeno-highchart-theme($theme);
  //@@INCLUDE
  // Include datatable mixins

  @include app-typography($config);

  .btn-color {
    .ruf-file-upload-button {
      background-color: ruf.ruf-color($accent) !important;
      border-color: ruf.ruf-color($accent) !important;
    }

    :hover {
      background-color: transparent !important;
    }

    .ruf-icon-before {
      color: ruf.ruf-color($primary) !important;
    }
  }

  .star-color {
    color: ruf.ruf-color($error);
  }
}



@mixin app-typography($theme, $config: ruf.ruf-typography-config()) {
  // include your typography mixins below
  @include dashboard.dashboard-typography($config);
  @include not-found.not-found-typography($config);
  @include side-menu.side-menu-typography($config);
  @include my-products-new.my-product-typography($config);
  @include my-products-panel.my-products-panel-typography($config);
  @include vendor-page.vendor-page-typography($config);
  @include support-page.support-page-typography($config);
  @include invoice-page.invoice-page-typography($config);
  @include bulletins-page.bulletins-page-typography($config);
  @include reports-page.reports-page-typography($config);
  @include administration-page.administration-page-typography($config);
  @include login-page.login-page-typography($config);
  @include community-page.community-page-typography($config);
  @include entity-page.entity-page-typography($config);
  // @include view-more-typography($config);
  //@include tickets datatable typography
  //@@TYPOGRAPHY
  .page-heading {
    font-family: sans-serif;
  }
}

@mixin cp-ruf-list-builder-theme($theme) {
  client-portal-lib-ebt-search {
     .ruf-list-builder .ruf-list-builder-selection-list{
  //  height:100px !important;
     }
    .ruf-list-builder-floater {
     // height: 364px !important;
    }
    .ruf-list-builder .ruf-list-builder-container{
      margin-top:0;
      justify-content: left;
      .mat-mdc-form-field{
        width: 200px !important;
      }
    }
 .ruf-list-builder-floater:nth-child(4){
      display: none !important;
    }
}

}


@mixin ruf-avatar-image-theme($theme) {

 .dash-user-sec .ruf-avatar-image.ruf-background-default{
    background-color:#4BCD3E;
  }
}
@mixin side1-menu-theme($theme) {
  client-portal-side-menu {
    background-color: #011E26;

    a.ruf-menubar-item-active {
    }
  }

  .user-links {

    .ruf-sidemenu a.ruf-menu-item {
      min-height: auto !important;
      line-height: 23px;
    }
  }

  .entity-to-emulate .mat-mdc-select-value {
    color: #fff !important;
  }

  .entity-to-emulate .mat-mdc-select-value-text {
    color: #fff !important;
  }
  // .mat-select[fisStyle] .mat-select-arrow{
  //   color:#fff;
  // }
  input.ng-pristine.ng-invalid.ng-touched {
    border: none !important;
  }
  .mdc-list-item__primary-text {
    padding: 6px 12px;
  }
  /* .mat-drawer-backdrop.mat-drawer-shown
{
  background-color:transparent !important;
  visibility: none !important;
} */
}

input:focus
{
  border: none !important;
}
