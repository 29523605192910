/* Importing the prebuilt color theme called "fis-default" */
@use '@ruf/shell' as ruf;
//@import 'node_modules/@ruf/theming/styles/theming/typography';
@use './components/cp-tickets-datatable/cp-tickets-datatable.theme' as cp-tickets-datatable;
@mixin ticket-all-theme($theme, $config: null) {
  @include tickets-datatable-theme($theme);
  @include tickets-typography($config);
}
@mixin tickets-typography($theme, $config: ruf.ruf-typography-config()) {
  // include your typography mixins below

  //@include tickets datatable typography

  @include tickets-datatable-typography($config);
  //@@TYPOGRAPHY
}
