/* Importing the prebuilt color theme called "fis-default" */
@use '@ruf/shell' as ruf;
@use '@ruf/prebuilt-themes/fis-default' as ruf-prebuilt-themes;
// Importing the RUF shell structural styles
@use '@ruf/shell/styles/index' as ruf-shell-style-index; /* Importing the prebuilt color theme called "fis-default" */

//@import 'node_modules/@ruf/theming/styles/theming/theming';
//@import 'node_modules/@ruf/material/src/card/card.theme';
@use './cp-main-invoices-page/_cp-main-invoices-page.theme' as cp-main-invoices-page;

/*@import 'node_modules/@ruf/theming/styles/theming/typography';*/



@mixin invoice-app-all-theme($theme, $config: null) {
  @include invoice-page-theme($theme, $config);
  @include invoice-page-typography($theme, $config);
}

@mixin invoice-page-theme($theme, $config: null) {
  $background: map-get($theme, background);
  $foreground: map-get($theme, foreground);
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $emphasis: map-get($theme, emphasis);
  $warn: map-get($theme, warn);

  //@include ruf-card-theme($theme);

  @include cp-main-invoices-page.cp-main-invoices-page-theme($theme);
}

@mixin invoice-page-typography($config: null) {
  .ruf-invoice-title {
    font-family: ruf.ruf-font-family($config, title);
    font-size: ruf.ruf-font-size($config, title);
    font-weight: ruf.ruf-font-weight($config, title);
  }

  .ruf-invoice-subtitle {
    font-family: ruf.ruf-font-family($config, title);
    font-size: ruf.ruf-font-size($config, caption);
  }

  @include cp-main-invoices-page.cp-main-invoices-page-typography($config);
}
