/* Importing the prebuilt color theme called "fis-default" */
//@import 'node_modules/@ruf/theming/styles/theming/typography';
@use './main-product-page/components/resources-tools/components/tab-training/components/cp-trainings-datatable.theme' as cp-trainings-datatable;
@mixin trainings-all-theme($theme, $config: null) {
  @include cp-trainings-datatable.trainings-datatable-theme($theme);
  @include trainings-typography($config);
}
@mixin trainings-typography($theme, $config: ruf-typography-config()) {
  // include your typography mixins below
  //@include tickets datatable typography

  @include cp-trainings-datatable.trainings-datatable-typography($config);
  //@@TYPOGRAPHY
}
