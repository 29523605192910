/* Importing the prebuilt color theme called "fis-default" */
@use "@ruf/shell" as ruf;
@use "@ruf/prebuilt-themes/fis-default" as ruf-prebuilt-themes;
// Importing the RUF shell structural styles
@use "@ruf/shell/styles/index" as ruf-shell-style-index; /* Importing the prebuilt color theme called "fis-default" */

//@import 'node_modules/@ruf/theming/styles/theming/theming';
//@import 'node_modules/@ruf/material/src/card/card.theme';

@use "./cp-resource-center/_cp-resource-center.theme" as cp-resource-center;
@use "./cp-risk-information/_cp-risk-information.theme" as cp-risk-information;
@use "./cp-gdpr/_cp-gdpr.theme" as cp-gdpr;
@use "./cp-indepth-conferences/_cp-indepth-conferences.theme" as cp-indepth-conferences;
@use "./cp-audit-controls-validation/_cp-audit-controls-validation.theme" as cp-audit-controls-validation;
@use "./cp-company-information/_cp-company-information.theme" as cp-company-information;
@use "./cp-fis-policy-summaries/_cp-fis-policy-summaries.theme" as cp-fis-policy-summaries;
@use "./cp-regulatory-matters/_cp-regulatory-matters.theme" as cp-regulatory-matters;
@use "./cp-industry-hot-topics/_cp-industry-hot-topics.theme" as cp-industry-hot-topics;
@use "./cp-side-menu-bar/_cp-side-menu-bar.theme" as cp-side-menu-bar;
@use "./cp-webinar-series/_cp-webinar-series.theme" as cp-webinar-series;
/*@import 'node_modules/@ruf/theming/styles/theming/typography';*/

// Importing the RUF shell structural styles

@mixin vendor-app-all-theme($theme, $config: null) {
  @include vendor-page.vendor-page-theme($theme, $config);
  @include vendor-page.vendor-page-typography($theme, $config);
}

@mixin vendor-page-theme($theme, $config: null) {
  $background: map-get($theme, background);
  $foreground: map-get($theme, foreground);
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $emphasis: map-get($theme, emphasis);
  $warn: map-get($theme, warn);

  @include cp-industry-hot-topics.cp-industry-hot-topics-theme($theme);
  @include cp-indepth-conferences.cp-indepth-conferences-theme($theme);
  @include cp-regulatory-matters.cp-regulatory-matters-theme($theme);
  @include cp-fis-policy-summaries.cp-fis-policy-summaries-theme($theme);
  @include cp-audit-controls-validation.cp-audit-controls-validation-theme($theme);
  @include cp-company-information.cp-company-information-theme($theme);
  @include cp-gdpr.cp-gdpr-theme($theme);
  @include cp-risk-information.cp-risk-information-theme($theme);
  @include cp-resource-center.cp-resource-center-theme($theme);
  @include cp-side-menu-bar.cp-side-menu-bar-theme($theme);
  @include cp-webinar-series.cp-webinar-series-theme($theme);
}

@mixin vendor-page-typography($config: null) {
  .ruf-vendor-title {
    font-family: ruf.ruf-font-family($config, title);
    font-size: ruf.ruf-font-size($config, title);
    font-weight: ruf.ruf-font-weight($config, title);
  }

  .ruf-vendor-subtitle {
    font-family: ruf.ruf-font-family($config, title);
    font-size: ruf.ruf-font-size($config, caption);
  }

  @include cp-industry-hot-topics.cp-industry-hot-topics-typography($config);
  @include cp-regulatory-matters.cp-regulatory-matters-typography($config);
  @include cp-fis-policy-summaries.cp-fis-policy-summaries-typography($config);
  @include cp-company-information.cp-company-information-typography($config);
  @include cp-audit-controls-validation.cp-audit-controls-validation-typography($config);
  @include cp-indepth-conferences.cp-indepth-conferences-typography($config);
  @include cp-gdpr.cp-gdpr-typography($config);
  @include cp-resource-center.cp-resource-center-typography($config);
  @include cp-risk-information.cp-risk-information-typography($config);
  @include cp-side-menu-bar.cp-side-menu-bar-typography($config);
  @include cp-webinar-series.cp-webinar-series-typography($config);
}
client-portal-cp-main-vendor-management {
  .mat-mdc-card {
    .mat-mdc-card {
      .ruf-margin-top-medium.ruf-margin {
        margin: 10px 15px;
      }
    }

    mat-accordion {
      .mat-expansion-panel .mat-expansion-panel-header .mat-expansion-indicator {
        position: relative;
        width: 30px;
        height: 30px;
        &::after {
          position: absolute;
        }
      }

      .mat-expansion-panel-header-description {
        flex-grow: 0;
      }
    }
    .button-text {
      margin-bottom: 0px !important;
    }
  }
}
