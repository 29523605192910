/*@import "node_modules/@ruf/theming/styles/theming/theming";
@import "node_modules/@ruf/ngx-datatable/styles/all-theme.scss";
@import "node_modules/@ruf/ngx-datatable/styles/_ngx-datatable.structure.scss";*/

@mixin trainings-datatable-theme($theme) {
  $background: map-get($theme, background);
  $foreground: map-get($theme, foreground);
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $emphasis: map-get($theme, emphasis);
  $warn: map-get($theme, warn);

  client-portal-trainings-datatable {
    .mat-mdc-table[fisStyle] {
      width: 100%;
      .mat-mdc-header-row {
        background: #003b4d;
        padding: 5px 0;
      }
      .mat-mdc-row:hover {
        background-color: #ebebeb !important;
        border-left: 4px solid #4bcd3e;
      }
      .mat-mdc-row {
        border-left: 4px solid transparent;
      }
      .mat-mdc-row:focus {
        outline: none;
      }
      .w-75 {
        max-width: 170px;
      }
      .w-590 {
        max-width: 590px;
      }
      .w-275 {
        max-width: 240px;
      }
      .th-center {
        justify-content: center;
      }

      .mat-mdc-header-cell {
        font-family: "Source Sans Pro", Arial, sans-serif;
        font-size: 13px;
        font-weight: normal;
        color: #fff;
        border-bottom: none;

        .mat-sort-header-arrow {
          color: #fff;
          opacity: 1 !important;

          height: inherit !important;
        }
      }

      .mat-mdc-header-row .mat-mdc-header-cell:hover:not(.mat-mdc-table-sticky) {
        background-color: transparent;
      }
    }
    .mat-mdc-paginator-container {
      justify-content: flex-start;
      padding-left: 0;
    }
    .Download-button-mat {
      cursor: pointer;
      font-weight: bold;
      margin-bottom: 25px;
      mat-icon {
        padding: 10px 0 0 0;
        color: #4bcd3e;
        vertical-align: text-bottom;
      }
      [aria-sort="ascending"] {
        ::ng-deep .mat-sort-header-arrow {
          .mat-sort-header-indicator {
            &::before {
              font: normal normal normal 1.1rem/1 FontAwesome;
              content: "\f0d7";
              position: absolute;
              top: 0.2rem;
            }
          }
        }
      }

      [aria-sort="descending"] {
        ::ng-deep .mat-sort-header-arrow {
          .mat-sort-header-indicator {
            &::before {
              font: normal normal normal 1.1rem/1 FontAwesome;
              content: "\f0d8";
              position: absolute;
              top: -0.9rem;
            }
          }
        }
      }
    }

    .mat-mdc-select-value-text {
      color: #858f94;
    }

    .mat-mdc-paginator-page-size-select {
      width: 40px;
    }

    .mat-form-field-appearance-outline .mdc-line-ripple {
      display: none;
    }

    .mat-mdc-floating-label {
      padding-left: 10px !important;
    }
  }

  // @include ruf-mat-datatable-theme($theme);
}

// @media only screen and (min-width: 768px) {
//   client-portal-trainings-datatable {
//     .mat-form-field-appearance-outline .mat-mdc-form-field-infix {
//       padding: 0.4375em 0;
//       border-radius: 2px;
//       top: 0;
//       // box-shadow: 0px 0px 1px 1px rgb(179 193 199);
//       outline: none;
//       margin-top: 0;
//       padding-left: 5px;
//       padding-top: 7px !important;
//       height: 35px;
//       position: relative;
//       padding-bottom: 0;
//       // border-top: 0;
//       border: 1px solid #b3c1c7;
//       border-radius: 4px;
//     }
//   }
// }

// @media only screen and (max-width: 991px) and (min-width: 768px) {
//   client-portal-trainings-datatable {
//     .mat-form-field-appearance-outline .mat-mdc-form-field-infix {
//       top: 0 !important;
//     }
//   }
// }

// @media only screen and (max-width: 991px) {
//   client-portal-trainings-datatable {
//     .mat-form-field-appearance-outline .mat-mdc-form-field-infix {
//       padding: 0.4375em 0;
//       border-radius: 2px;
//       top: 18px;
//       // box-shadow: 0px 0px 1px 1px rgb(179 193 199);
//       outline: none;
//       margin-top: 0;
//       padding-left: 5px;
//       padding-top: 7px !important;
//       height: 35px;
//       position: relative;
//       padding-bottom: 0;
//       // border-top: 0;
//       border: 1px solid #b3c1c7;
//       border-radius: 4px;
//     }
//   }
// }

@media only screen and (max-width: 767px) {
  .my-setting {
    font-size: 20px;
  }
  client-portal-trainings-datatable {
    .entire-mat-page-btn {
      position: relative;
    }
    .paginator-rows {
      margin-bottom: 20px;
    }
    .search-matpaginator-main {
      width: calc(100% - 85px);
      margin: 0;
      float: left;
      display: inline-block;
    }

    .search-matpaginator-main .mat-mdc-paginator-page-size-select {
      // width: 36px;
      width: 65px;
      margin: 14px 0 0 0;
    }

    .mat-mdc-paginator-outer-container {
      width: 100%;
    }

    .gotojump {
      right: 16px;
      top: 2px;
    }

    .gotojump .mat-mdc-text-field-wrapper {
      width: 45px;
      margin-left: 24px !important;
      margin-top: 16px !important;
      margin-right: 0 !important;
    }

    .search-matpaginator-main .mat-mdc-paginator-page-size {
      width: 30%;
      margin: 0;
      padding-right: 12px;
    }
    .search-matpaginator-main .mat-mdc-paginator-range-actions {
      width: 70%;
      padding-left: 40px;
      // margin-top: 10px;
    }
    .mat-mdc-paginator[fisStyle] .mat-mdc-paginator-navigation-previous {
      left: 80px;
      top: -6px;
      // position: absolute;
      display: none;
    }
    .mat-mdc-paginator[fisStyle] .mat-mdc-paginator-navigation-next {
      right: -60px;
      top: -6px;
      // position: absolute;
      display: none;
    }
  }
  .mat-mdc-paginator-range-label {
    margin: 0 !important;
    min-width: 235px;
    font-size: 13px;
  }
  .mat-mdc-paginator-page-size {
    .mat-mdc-paginator-page-size-label {
      display: none;
    }
  }
}
@media only screen and (min-width: 768px) {
  client-portal-trainings-datatable {
    .mat-paginator .mat-mdc-paginator-navigation-next {
      right: -60px;
    }
    .gotojump {
      width: 44px;
    }
    .mat-mdc-paginator-range-actions {
      .mat-mdc-paginator-navigation-previous {
        position: absolute;
        right: 100px;
      }
      .mat-mdc-paginator-navigation-next {
        position: absolute;
        right: 10px;
      }
    }
    .filter-mattable {
      margin-bottom: -15px;
    }
    .entire-mat-page-btn {
      // margin-top: 20px;
    }
  }
}

@mixin trainings-datatable-typography($config) {
  client-portal-trainings-datatable {
    .mat-mdc-table[fisStyle] {
      mat-row {
        border-bottom: none;
      }
      .mat-mdc-header-row {
        .mat-mdc-header-cell {
          color: #fff;
          border-bottom: none;
        }
      }
    }
  }
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  client-portal-trainings-datatable {
    .mat-mdc-table[fisStyle] .mat-sort-header-arrow {
      top: -10px;
    }
  }
}
