/* Importing the prebuilt color theme called "fis-default" */
@use "@ruf/prebuilt-themes/fis-default" as ruf-prebuilt-themes;
// Importing the RUF shell structural styles
@use "@ruf/shell/styles/index" as ruf-shell-style-index; /* Importing the prebuilt color theme called "fis-default" */

@use "./lib-cp-ReleasesTab-theme" as cp-ReleasesTab;
@use "./lib-cp-StrategyTab-theme" as cp-StrategyTab;
@use "./lib-cp-ForumTab-theme" as cp-ForumTab;
@use "./lib-cp-DocumentationTab-theme" as cp-DocumentationTab;
@use "./lib-cp-DownloadTab-theme" as cp-DownloadTab;
@use "./lib-cp-ServicesTab-theme" as cp-ServicesTab;
@use "./lib-cp-LoyaltyTab-theme" as cp-LoyaltyTab;
@use "./lib-cp-ResellerTab-theme" as cp-ResellerTab;
@use "./lib-cp-TrainingsTab-theme" as cp-TrainingsTab;
@mixin my-product-theme($theme) {
  @include cp-StrategyTab.strategy-all-theme($theme);
  @include cp-ForumTab.forum-all-theme($theme);
  @include cp-ReleasesTab.release-all-theme($theme);
  @include cp-DownloadTab.download-all-theme($theme);
  @include cp-DocumentationTab.documentation-all-theme($theme);
  @include cp-ServicesTab.services-all-theme($theme);
  @include cp-LoyaltyTab.loyalty-all-theme($theme);
  @include cp-ResellerTab.reseller-resources-all-theme($theme);
  @include cp-TrainingsTab.trainings-all-theme($theme);
}
@mixin my-product-typography($config) {
}
client-portal-main-product-page {
  .mat-mdc-tab-group {
    font-family: "Titillium Web" !important;
    font-weight: bold !important;
  }

  .mat-mdc-select-placeholder {
    font-size: 14px;
  }

  mat-form-field {
    .mat-mdc-form-field-flex {
      .mdc-notched-outline {
        //min-height: 2.25rem !important;
      }
    }
  }

  .strategy-filter-mattable .mat-mdc-form-field.mat-form-field-appearance-legacy[fisStyle] .mat-mdc-input-element,
  *[fisStyle] .mat-mdc-form-field.mat-form-field-appearance-legacy .mat-mdc-input-element,
  .mat-mdc-form-field .mat-mdc-floating-label span,
  .mat-mdc-select[fisStyle] .mat-mdc-select-trigger .mat-mdc-select-placeholder {
    font-size: 14px;
    // color: #858f94 !important;
    // padding-left: 8px;
    // padding-right: 8px;
  }
  //resolve for all search/filter by box in product tabs
  .mat-mdc-form-field.mat-form-field-appearance-outline[fisStyle]
    .mdc-text-field.mdc-text-field--outlined
    .mdc-notched-outline {
    border-width: 2px;
  }

  .mat-mdc-form-field .mat-mdc-floating-label span {
    color: #858f94 !important;
  }

  .mat-mdc-select[fisStyle] .mat-mdc-select-trigger .mat-mdc-select-placeholder {
    color: #858f94 !important;
  }

  .mat-form-field-appearance-outline:not(.mat-form-field-appearance-legacy)[fisStyle]
    .mat-mdc-input-element::placeholder,
  .mat-form-field-appearance-outline:not(.mat-form-field-appearance-legacy)[boxStyle]
    .mat-mdc-input-element::placeholder {
    color: #858f94;
  }

  .mat-mdc-form-field.mat-form-field-appearance-legacy[fisStyle] .mat-mdc-form-text-infix,
  *[fisStyle] .mat-mdc-form-field.mat-form-field-appearance-legacy .mat-mdc-form-text-infix,
  .mat-mdc-form-field.mat-form-field-appearance-legacy[fisStyle] .mat-mdc-form-text-infix,
  *[fisStyle] .mat-mdc-form-field.mat-form-field-appearance-legacy .mat-mdc-form-text-infix {
    padding-left: 8px !important;
    padding-right: 8px !important;
  }

  .mat-mdc-table[fisStyle] .mat-mdc-header-cell {
    border-bottom-style: none;
  }

  .mat-mdc-table[fisStyle] .mat-mdc-header-row *,
  .mat-mdc-table[fisStyle] .mat-mdc-row .mat-mdc-cell {
    font-size: 14px !important;

    @media (max-width: 767px) {
      padding: 6px 0;
    }

    a {
      color: #012834;
    }

    a:hover {
      color: #48c43b;
    }
  }

  .mat-mdc-paginator-page-size-label {
    font-weight: 400;
    font-size: 16px;

    @media (max-width: 767px) {
      font-size: 13px;
    }
  }

  .mat-mdc-paginator {
    font-weight: bold !important;
  }

  .mat-mdc-paginator-page-size .mat-mdc-select-trigger,
  .mat-mdc-paginator,
  .mat-mdc-tab .mdc-tab__content {
    font-size: 16px !important;

    @media (max-width: 767px) {
      font-size: 13px !important;
    }
  }

  .mat-mdc-card > h2 {
    font-size: 24px;
  }

  .mat-form-field-appearance-outline .mdc-notched-outline__leading,
  .mat-form-field-appearance-outline .mdc-notched-outline__notch {
    border-color: #b3c1c7 !important;
  }

  .mat-form-field-disabled {
    label {
      color: #012834 !important;
    }

    .mdc-notched-outline {
      background: #f5f5f5 !important;
      color: #012834 !important;
      font-weight: normal;
    }
  }

  /*@media only screen and (min-width: 1300px) {
    .paginator-rows {
      align-items: baseline !important;
    }
  }*/

  @media only screen and (min-width: 1024px) and (max-width: 1280px) {
    .mat-mdc-tab .mdc-tab__content {
      font-size: 14px !important;
    }

    client-portal-product-banner-slider {
      height: 100%;
      display: flex;
    }

    client-portal-hero-comp .product-right-title {
      // margin-left: 0;
      //margin-top: 10px;
      width: 100%;
    }

    client-portal-hero-comp .product_wrap {
      flex-direction: row !important;

      .product_owner {
        max-width: 66%;
        max-height: max-content !important;
      }

      .product_slider {
        max-width: 34%; /*max-height: 34%;*/
      }
    }
  }

  @media (max-width: 767px) {
    .entire-mat-page-btn {
      .mat-mdc-form-field {
        width: 100%;
      }
    }
  }
  // Strategy Table UI

  .strategy-filter-mattable .mat-mdc-form-field .mat-mdc-text-field-wrapper,
  .entire-mat-page-btn .mat-mdc-form-field .mat-mdc-text-field-wrapper {
    padding-bottom: 0 !important;
    padding-top: 0 !important;
  }

  .entire-mat-page-btn .idp-mysettings-my-subscriptions-col .tablabel {
    margin-top: -10px;
  }

  .mat-column-DocumentName {
    .mat-sort-header-container {
      pointer-events: visible;
    }
  }

  .editBtn {
    .tertiary-small {
      a {
        display: flex;
        align-items: center;

        mat-icon {
          height: auto;
          top: 0;
        }
      }

      @media screen and (max-width: 767px) {
        a {
          margin-right: 0;
        }
      }
    }
  }

  .tertiary-btn {
    display: flex;
    align-items: center;

    mat-icon {
      top: 0 !important;
      line-height: 1;
      height: auto;
    }
  }

  .tertiary-btn-help-link {
    display: flex;
    align-items: center;
    width: fit-content;

    mat-icon {
      top: 0 !important;
      line-height: 1;
      height: auto;
    }
  }

  .cdk-overlay-pane.fis-style .mat-dialog-title {
    font-size: 22px !important;
  }
  // Tables common styles

  .mat-mdc-row,
  .mat-mdc-header-row {
    display: flex;
    justify-content: space-between;
  }

  .w-10 {
    max-width: 80px;
  }

  .w-45 {
    max-width: 140px;
  }

  .w-75 {
    max-width: 90px;

    @media screen and (min-width: 1600px) {
      max-width: 150px;
    }
  }

  .w-150 {
    max-width: 150px;
  }

  .w-200 {
    max-width: 200;
  }

  .w-590 {
    max-width: 700px;
  }

  .w-275 {
    max-width: 240px;
  }

  .th-center {
    justify-content: center;
    text-align: center;
  }

  .mat-column-Action a {
    color: #00242d;
  }

  @media screen and (min-width: 992px) {
    .strategy-filter-mattable {
      .mat-mdc-form-field-type-mat-input {
        min-width: 260px;
      }
    }

    .entire-mat-page-btn {
      .mat-mdc-form-field-type-mat-select {
        min-width: 200px;
      }
    }
  }

  .mat-mdc-paginator-outer-container {
    .mat-mdc-form-field-type-mat-input,
    .mat-mdc-form-field-type-mat-select {
      min-width: 1px;
    }
  }

  .table-pagination-block {
    .mat-mdc-form-field-type-mat-input,
    .mat-mdc-form-field-type-mat-select {
      min-width: 1px;
    }
  }

  @media screen and (max-width: 767px) {
    .mat-mdc-table[fisStyle] .mat-mdc-row {
      .mat-mdc-cell.mat-column-Document-type {
        display: flex;
        align-items: center;
        justify-content: flex-start;
      }
    }

    .table-pagination-block {
      margin-top: 0 !important;
      padding-top: 16px !important;
    }

    .search-matpaginator-main .mat-mdc-paginator-page-size-select {
      width: 50px !important;
      margin: 0 !important;
    }

    .gotojump {
      width: 50px !important;
      right: 20px !important;
      margin-top: 0;
      top: 18px;

      .mat-mdc-text-field-wrapper {
        margin: 0 !important;
        width: 50px;
      }
    }

    .mat-mdc-card > h2 {
      font-size: 24px;
    }

    .paginator-rows {
      margin-bottom: 32px !important;

      h2 {
        font: normal normal bold 24px/32px Titillium Web;
        letter-spacing: 0px;
        color: #012834;
        text-transform: capitalize;
      }
    }

    .mobile-label {
      width: 40%;
      max-width: 40% !important;
      display: inline-block;
      font-weight: bold;
      padding-right: 10px;
    }

    .mat-column-Action {
      justify-content: flex-start !important;
    }
  }

  .img-icon-external {
    max-width: 31px !important;
  }

  .table-pagination-block {
    position: relative;

    .mat-mdc-paginator-range-actions {
      .mat-mdc-paginator-range-label {
        position: relative;
        //top: -4px; //Note-RufUpgrade: Not require
      }
    }

    .gotojump {
      top: 26px;
    }
  }

  @media screen and (min-width: 767px) and (max-width: 991px) {
    .paginator-rows {
      margin-bottom: 30px;
      flex-direction: row !important;
      justify-content: space-between;
    }

    client-portal-product-banner-slider {
      height: 100%;
      display: flex;
    }

    client-portal-hero-comp .product-right-title {
      margin-left: 0;
      margin-top: 10px;
      width: 100%;
    }

    client-portal-hero-comp .fav-bar {
      display: none;
    }
  }

  @media screen and (max-width: 767px) {
    .mat-form-field-appearance-legacy .mat-mdc-form-text-infix {
      top: 0 !important;
    }

    .tablabel {
      padding-right: 5px;
      width: 70px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: left;
      margin-top: -10px;
    }

    .idp-mysettings-my-subscriptions-col {
      width: 100%;
      display: flex;
      flex-direction: row;
    }
  }
}
