/* Importing the prebuilt color theme called "fis-default" */
@use '@ruf/shell' as ruf;

@use "@ruf/prebuilt-themes/fis-default" as ruf-prebuilt-themes;
// Importing the RUF shell structural styles
@use '@ruf/shell/styles/index' as ruf-shell-style-index;/* Importing the prebuilt color theme called "fis-default" */


//@import 'node_modules/@ruf/theming/styles/theming/theming';
//@import 'node_modules/@ruf/material/src/card/card.theme';
@use '../lib/cp-product-list/cp-product-list.theme' as cp-product-list;
@use '../lib/cp-product-view/cp-product-view.theme' as cp-product-view;
@use '../lib/cp-support-dialog/cp-support-dialog.theme' as cp-support-dialog;
@use '../lib/cp-support-page/cp-support-page.theme' as cp-support-page;
@use '../lib/cp-support-side-menu-bar/cp-support-side-menu-bar.theme' as cp-support-side-menu-bar;

/*@import 'node_modules/@ruf/theming/styles/theming/typography';*/

// Importing the RUF shell structural styles



@mixin support-app-all-theme ($theme, $config: null) {
    @include support-page-theme($theme, $config);
    @include support-page-typography($config);
}



@mixin support-page-theme ($theme, $config: null) {
  $background: map-get($theme, background);
  $foreground: map-get($theme, foreground);
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $emphasis: map-get($theme, emphasis);
  $warn: map-get($theme, warn);

  @include cp-product-list.cp-product-list-theme($theme);
  @include cp-product-view.cp-product-view-theme($theme);
  @include cp-support-dialog.cp-support-dialog-theme($theme);
  @include cp-support-page.cp-support-page-theme($theme);
  @include cp-support-side-menu-bar.cp-support-side-menu-bar-theme($theme);
}

@mixin support-page-typography($config: null) {
  .ruf-support-title {
    font-family: ruf.ruf-font-family($config, title);
    font-size: ruf.ruf-font-size($config, title);
    font-weight: ruf.ruf-font-weight($config, title);
  }

  .ruf-support-subtitle {
    font-family: ruf.ruf-font-family($config, title);
    font-size: ruf.ruf-font-size($config, caption);
  }

  .ruf-cursor {
    cursor: pointer;
  }

  @include cp-product-list.cp-product-list-typography($config);
  @include cp-product-view.cp-product-view-typography($config);
  @include cp-support-dialog.cp-support-dialog-typography($config);
  @include cp-support-page.cp-support-page-typography($config);
  @include cp-support-side-menu-bar.cp-support-side-menu-bar-typography($config);
}
