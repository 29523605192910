/* Importing the prebuilt color theme called "fis-default" */
@use '@ruf/shell' as ruf;
@use "@ruf/prebuilt-themes/fis-default" as ruf-prebuilt-themes;
// Importing the RUF shell structural styles
@use '@ruf/shell/styles/index' as ruf-shell-style-index; /* Importing the prebuilt color theme called "fis-default" */


//@import 'node_modules/@ruf/theming/styles/theming/theming';
//@import 'node_modules/@ruf/material/src/card/card.theme';

@use './components/cp-submit-an-idea/cp-submit-an-idea-theme' as cp-submit-an-idea;
@use './components/cp-view-more/cp-view-more-theme' as cp-view-more;
@use './cp-main-community/cp-main-community-theme' as cp-main-community;
@use './cp-confirmation-dialog/_cp-confirmation-dialog.theme' as cp-confirmation-dialog;
/*@import 'node_modules/@ruf/theming/styles/theming/typography';*/

//@import './components/cp-header/cp-header-theme';




@mixin commmunity-app-all-theme ($theme, $config: null) {
  @include community-page-theme-card ($theme);
  @include ruf.ruf-sidemenu-theme ($theme);
  // TODO(ruf-v512-migration): Signature of this mixin has been changed
// The new signature is: @mixin mat-tabs-theme($theme: ruf.get-default-basic-css-vars-theme(), $customizations: (), $cls: [fisStyle]);
// Manual intervention is required to fix the signture
// If you want to learn more about the mixin and its parameters, please visit the "tabs's overview" page on the RUF demo site.
@include ruf.mat-tabs-theme($theme);
  @include cp-community-main-theme($theme);
  @include cp-community-main-typography($theme);
  
}


// a convenience mixin that aggregates all of your component theme mixins
@mixin community-page-theme($theme, $config: ()) {
  $background: map-get($theme, background);
  $foreground: map-get($theme, foreground);
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $emphasis: map-get($theme, emphasis);
  $warn: map-get($theme, warn);

  @include cp-submit-an-idea.cp-submit-an-idea-theme($theme);
  @include cp-confirmation-dialog.cp-confirmation-dialog-theme($theme);
  @include cp-view-more.cp-view-more-theme($theme);
}

@mixin community-page-typography($config: null) {
  .ruf-community-title {
    font-family: ruf.ruf-font-family($config, title);
    font-size: ruf.ruf-font-size($config, title);
    font-weight: ruf.ruf-font-weight($config, title);
  }

  .ruf-community-subtitle {
    font-family: ruf.ruf-font-family($config, title);
    font-size: ruf.ruf-font-size($config, caption);
  }

  @include cp-view-more.cp-view-more-typography($config);
  @include cp-submit-an-idea.cp-submit-an-idea-typography($config);
  @include cp-confirmation-dialog.cp-confirmation-dialog-typography($config);
}

