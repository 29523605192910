/*@use '../../../themes/CPtheme';*/
//@use '../../../themes/customTheme';
/* You can add global styles to this file, and also import other style files */
/*@use "./client-portal-theme.scss";*/
@use "./mytheme.scss";

/*@use "../../../libs/lib-cp-tickets/src/lib/cp-tickets-comp-theme";
@use "../../../libs/cp-community/src/lib/cp-community-theme";
@use "../../../libs/cp-ebt-tickets-lib/src/lib/_cp-ebt-tickets-lib-theme";
@use "../../../libs/cp-vendor-management/src/lib/cp-vendor-management-theme";
@use "../../../libs/cp-support-page/src/lib/cp-support-page.theme";*/
/*@import "../../../libs/cp-invoices-page/src/lib/cp-invoices-page-theme";*/
/*@import "../../../libs/cp-bulletins-page/src/lib/cp-bulletins-page-theme";*/
/*@import "../../../libs/cp-reports-page/src/lib/cp-reports-page-theme";*/
/*@import "../../../libs/cp-administration/src/lib/cp-administration-theme";*/
/*@import "../../../libs/cp-login-page/src/lib/cp-login-page-theme";*/
/*@import "../../../libs/cp-entity-page/src/lib/cp-entity-page.theme";*/
/*@use "../../../libs/cp-lib-tickets-details/src/lib/_cp-lib-tickets-details-theme";

@use "../../../libs/my-products-new/src/lib/my-product-theme" as my-products-new;*/

/*@mixin mytheme($theme, $config: null) {
  @include mytheme.mytheme($theme);
}

@mixin app-typography($theme, $config: ruf.ruf-typography-config()) {
  @include mytheme.mytheme-typography($config);
}
*/
// $Titillium-Bold : "Titillium Web, Bold";

.mat-mdc-card {
  transition: box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1);
  display: block;
  position: relative;
  padding: 16px;
  border-radius: 4px;
  border: 0 !important; //RufUpgrade css change
  .ruf-form-field-textarea {
    border: 1px solid #dfdfdf;
    border-radius: 4px;
  }
}

.mdc-button.mdc-button--unelevated.mat-accent,
.mdc-button--unelevated.mdc-button:not([disabled]) {
  background-color: #71c954 !important;
  border: 2px solid #71c954;
  box-shadow: none;
  color: #012834 !important;
  &:hover {
    background-color: rgba(255, 255, 255, 0) !important;
    border-color: #4bcd3e !important;
  }
}

.mdc-button--unelevated.mdc-button:disabled {
  background-color: rgba(0, 0, 0, 0.12);
  color: rgb(179, 191, 194);
  border: none;
  &:hover {
    background-color: rgba(0, 0, 0, 0.12);
    border: none !important;
  }
}
.mdc-button.mdc-button--outlined.mat-accent {
  border-color: #71c954;
  color: #012834;
}
client-portal-side-menu {
  background-color: #011e26;
}
.mat-mdc-autocomplete-panel {
  border-style: solid !important;
  border-width: 0.0625rem !important;
  display: inline-block !important;
  border-color: lightgray !important;
  box-shadow: none !important;
  margin-top: -10px !important;
}

.typeheadOptions .mdc-list-item__primary-text {
  font-size: 14px;
}

.mat-mdc-checkbox-checked.mat-primary .mdc-checkbox__background {
  background: #71c954 !important;
  border-color: #71c954 !important;
}

.gotojump .mat-mdc-text-field-wrapper {
  padding-top: 0px;
}

.cdk-global-overlay-wrapper,
.cdk-overlay-container,
.cdk-overlay-connected-position-bounding-box {
  z-index: 99999 !important;
}
/*CSS for removing checkbox and tick icon
.cdk-overlay-container {
  mat-option {
    mat-pseudo-checkbox{display: none;}
  }
}*/
.mat-mdc-option.mdc-list-item {
  align-items: center;
  font-size: 14px;
  height: 2.125rem;
  min-height: 2.125rem;
  padding-left: 0.75rem;
  padding-right: 0.75rem;
}

.ruf-sidemenu .ruf-menu-item {
  font-size: 14px;
}

.user-links .ruf-sidemenu a.ruf-menu-item {
  min-height: auto !important;
  line-height: 23px;
}

client-portal-root {
  mat-sidenav.mat-drawer-inner-container {
    overflow: hidden !important;
  }
}

client-portal-snow-ticket .mat-mdc-tab {
  min-width: 130px !important;
  opacity: 1 !important;
  font-weight: 400;
  font-size: 16px;
}

client-portal-snow-ticket .mat-mdc-tab-group.mat-primary .mat-ink-bar {
  background-color: #4bcd3e !important;
  height: 4px !important;
}

client-portal-snow-ticket .mdc-tab--active .mdc-tab__content {
  color: #012834 !important;
  font-weight: 700 !important;
  opacity: 1 !important;
}
client-portal-snow-ticket{
  .form-full-width {
   .mat-mdc-form-field{
    .mat-mdc-text-field-wrapper{
     //.mat-mdc-form-field-flex {padding: 5px 0;}//to be check
    }
    .mat-mdc-form-field-subscript-wrapper {display: none;}
   }
  }
    /*Tab CSS*/
  .mat-mdc-tab-label-container {
    .mat-mdc-tab-list {
      display: flex;

      .mat-mdc-tab-labels {
        flex: 0 0 auto;
      }
    }
  }
}
.client-portal-snow-ticket .mat-mdc-dialog-container{padding: 1.25rem;}
client-portal-snow-ticket-modal ruf-dialog-panel{padding: 1.25rem;}
client-portal-autocomplete .auto-search {
  width: 35%;
  margin: auto;

  .mat-mdc-form-field {
    width: 100%;
    .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex {
      .mdc-notched-outline {
        color: #012834 !important;
        border-top: none !important;
        border-left: none !important;
        border-right: none !important;
        border-bottom: 2px solid rgb(133 143 148 / 25%) !important;
        top: -5px;
        height: 3rem;
        min-height: 3rem;
      }
    }
    .mat-form-field-appearance-outline:not(.mat-mdc-form-field-disabled) .mat-mdc-form-field-flex:focus .mdc-notched-outline {
      border-bottom: 2px solid rgb(255 255 255);
    }
    .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-form-infix input {
      height: 30px;
      color: #fff;
      font-weight: 600;
      font-size: 24px;
      padding-left: 10px;
      padding-right: 35px;
    }
    .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-form-infix input:focus {
      color: #012834 !important;
      position: relative;
      top: 0;
    }
    .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-form-infix input::placeholder {
      color: #012834 !important;
      text-align: center !important;
      font-size: x-large !important;
      font-weight: bold !important;
    }
    .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-form-infix input:focus::placeholder {
      color: transparent !important;
    }
    .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-form-field-icon-prefix mat-icon {
      color: #012834 !important;
      font-size: 24px !important;
    }
  }
  .typed .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-form-field-outline {
    color: #012834;
    border-bottom: none;
    top: 5px;
    background: #fff;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    z-index: -1;
    border-radius: 5px;
  }
  .typed .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-form-field-outline {
    border-bottom: 2px solid rgb(255 255 255);
  }
  .typed .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-form-field-icon-prefix mat-icon {
    color: #012834 !important;
  }
  .typed .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-form-infix input {
    color: #012834 !important;
    font-weight: 400;
  }

  .recent-search {
    background: #012834;
    color: white;
    text-align: left;
    padding: 20px;
    position: relative;
    top: -7px;
    h4 {
      font-size: 16px;
      margin: 10px 0;
    }
    ul {
      list-style: none;
      margin: 0;
      padding: 0;
      li {
        width: 100%;
        display: inline-block;
        margin-bottom: 10px;

        a {
          color: #3bcff0;
          font-size: 12px;
        }
      }
    }
  }

  #globalSearchtxt::placeholder {
    color: #012834 !important;
    text-align: center !important;
    font-size: x-large !important;
    font-weight: bold !important;
  }
}
.mat-form-field-appearance-outline:not(.mat-form-field-appearance-legacy)[boxStyle] .mdc-notched-outline,
.mat-form-field-appearance-outline:not(.mat-form-field-appearance-legacy)[fisStyle] .mdc-notched-outline {
  height: unset !important;
  min-height: 2.25rem; // CSS height for default
  //min-height: 2.875rem;// CSS change for RUF Upgrade
  outline-color: transparent !important;
}
.mat-form-field-appearance-outline.ruf-form-field-textarea[boxStyle] .mdc-notched-outline {
  border: 0 !important;
}

client-portal-resources-tools
  .entire-mat-page-btn
  .mat-form-field-appearance-outline:not(.mat-form-field-appearance-legacy)
  .mdc-notched-outline,
client-portal-resources-tools
  .entire-mat-page-btn
  .mat-form-field-appearance-outline:not(.mat-form-field-appearance-legacy)
  .mdc-notched-outline {
  //height: 36px !important; //Note-RufUpgrade: Not require
}

// main heading
.page-main-heading {
  font-size: 36px;
  font-family: "Titillium Web";
}

.page-description {
  font-size: 16px;
  font-weight: normal;
  font-family: "Source Sans Pro";
}

.button-text {
  //tabs-text
  font-size: 14px;
  font-family: "Source Sans Pro";
  font-weight: bold;
}
.side-menu-text {
  //expansion-panel-header text
  font-size: 16px;
  font-family: "Source Sans Pro";
  font-weight: bold;
}
.side-menu-active {
  background-color: #e0e5e6;
  font-size: 14px;
  font-weight: bold;
}

.side-menu-hover:hover {
  background-color: #e0e5e6 !important;
  font-size: 14px;
  .page-description {
    font-weight: bold;
  }
}

.card-heading {
  font-size: 24px;
  font-family: "Titillium Web";
  font-weight: bold;
}
// page sub-heading
.group-heading {
  font-size: 32px;
  font-family: "Titillium Web";
  font-weight: bold;
}
.card-description {
  font-size: 14px;
  font-family: "Source Sans Pro";
}
.card-button {
  font-size: 18px;
  font-family: "Source Sans Pro";
  font-weight: bold;
}
.link-underline:hover {
  text-decoration: underline;
}

.icon-color-accent {
  color: #009775;
}

.filter-background {
  background: #f5f5f5;
  border-top: 1px solid #b3bfc2;
  border-bottom: 1px solid #b3bfc2;

  // ---- for inputs background color --------
  .mat-mdc-text-field-wrapper {
    // padding: 0px !important; //RufUpgrade css change
    margin: 0px !important;
  }
  /*TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
  .mat-checkbox-inner-container {
    background-color: rgb(255, 255, 255);
  }
}

.mat-mdc-raised-button[fisStyle].mat-secondary:not([disabled]).cdk-keyboard-focused,
.mat-mdc-raised-button[fisStyle].mat-secondary:not([disabled]).cdk-program-focused,
.mat-mdc-unelevated-button[fisStyle].mat-secondary:not([disabled]).cdk-keyboard-focused,
.mat-mdc-unelevated-button[fisStyle].mat-secondary:not([disabled]).cdk-program-focused {
  border-color: #f8d50b;
  box-shadow: 0 0 0 2px #f8d50b;
}

//  ------ table design --------

.table-scroll {
  width: 100%;
  overflow: auto;
  position: relative;
  overflow-y: hidden;
  .mat-mdc-paginator[fisStyle] .mat-mdc-paginator-page-size-select {
    width: 50px;
  }
  .mdc-notched-outline {
    margin-top: 5px;
  }
  .mat-mdc-select-value {
    display: table !important;
  }

  .mat-mdc-select {
    text-align: center;
  }
}

.mat-mdc-header-row {
  background: #003b4d !important;
}
.mat-mdc-table[fisStyle] .mat-mdc-header-row * {
  color: #ffffff;
  font-family: "Source Sans Pro";
}
/*----------Table command Style---------*/
//.admin-table, .min-height {
  .mat-mdc-table[fisStyle] {
    .mat-mdc-header-row{
      align-items: center;
     .mat-mdc-header-cell {
       color: #ffffff;
       padding: 0.6rem 15px;
       width: 10%;
       &:nth-child(1){margin-left: 4px;}
      .mat-sort-header-container{justify-content: start !important;}
      .mat-sort-header-arrow{
        margin: 0 0 0 10px !important;
        .mat-sort-header-indicator::before{
            position: static !important;
        }
      }
     }
    }
    .mat-mdc-row {
      .mat-mdc-cell {
        font-size: 14px;
        border-bottom-color: transparent;
        padding: 0.6rem 15px;
        width: 10%;
        text-align: left;
        &:not(.ruf-datatable-no-data-cell):first-child {
          border-left: 5px solid transparent;
        }
      }
      &:nth-child(even) {
        background-color: #f5f5f5;
      }
      &:hover {
        //background-color: rgba(0, 0, 0, 0.1) !important;
        .mat-mdc-cell:not(.ruf-datatable-no-data-cell):first-child {
          border-left: 5px solid #4bcd3e;
        }
      }
    }
  }
  .ruf-datatable-no-data-message-icon {
    font-size: 50px;
  }
//}

//date picker

.mat-calendar-previous-button,
.mat-calendar-next-button {
  color: #4bcd3e !important;
}
.mat-calendar.fis-style.mat-calendar-body-cell-content.mat-calendar-body-today,
.mat-calendar.mat-calendar-body-cell-content.mat-calendar-body-today {
  border: 1px solid #4bcd3e !important;
}

// ------ no data found -------
.ruf-datatable-no-data-message-headline {
  font-size: 22px;
  font-weight: bold;
  font-family: "Titilium web";
}

.ruf-datatable-no-data-message-caption {
  font-size: 16px;
}

// ------ input font-size ------

.mat-mdc-select[fisStyle] .mat-mdc-select-trigger .mat-mdc-select-placeholder,
.mat-mdc-select-value .mat-mdc-select-min-line,
input {
  font-size: 14px !important;
}

// ------ paginator -------

.ruf-datatable-footer,
.mat-mdc-paginator-container {
  justify-content: flex-start !important;
  padding-left: 0% !important;
}
.mat-mdc-paginator-page-size-label {
  margin-left: 0% !important;
  width: max-content;
}
.alignCenter {
  text-align: center;
}
.pagination-design {
  .mat-mdc-paginator-range-actions {
    //margin-top: -5px;
    position: relative;
  }

  .mat-mdc-paginator-navigation-next {
    position: absolute !important;
    right: -140px !important;
  }

  .mat-mdc-paginator-navigation-previous {
    position: absolute !important;
    right: -43px !important;
  }
}

.mat-mdc-select-value .mat-mdc-select-min-line {
  font-size: 16px;
}

.go-to-container {
  position: absolute;
  bottom: 12px;
  left: 362px;
  display: flex;
  align-items: baseline;
  background: white;

  mat-form-field {
    width: 45px;
    margin: 6px 4px 0 4px;
    font-size: 12px;
  }

  .mdc-notched-outline {
    margin-top: 0px;
  }
  .mat-form-field-appearance-outline:not(.mat-form-field-appearance-legacy):not(.ruf-form-field-textarea)[boxStyle]
    .mdc-notched-outline,
  .mat-form-field-appearance-outline:not(.mat-form-field-appearance-legacy):not(.ruf-form-field-textarea)[fisStyle]
    .mdc-notched-outline {
    /*min-height: 30px !important;
    position: absolute;
    top: 2px;
    height: 34px !important;*/
    color: rgb(223, 223, 223);
  }
}

.mat-mdc-paginator-range-label {
  min-width: 120px;
  margin-right: 0px !important;
}

.input-padding .mat-mdc-text-field-wrapper {
  // padding: 0 !important;
}

client-portal-cp-main-bulletins-page {
  .mat-mdc-card {
    padding: 30px;
    .mat-mdc-text-field-wrapper {
      margin-top: 0 !important;
      margin-left: 0;
    }

    .mat-mdc-form-field-subscript-wrapper {
      display: none;
    }
  }

  .go-to-container {
    background: transparent;
    bottom: 0;
    left: 367px;
    .mat-mdc-text-field-wrapper.mdc-text-field.mdc-text-field--outlined {
      width: 50px;
    }
  }

  .pagination-design {
    /*margin-top: 20px;
    position: relative;*/
    .mat-mdc-select {
      //width: 50px;
    }
  }
  .mat-mdc-text-field-wrapper .mdc-text-field__input,
  .mat-mdc-text-field-wrapper .mat-mdc-select {
    //padding: 0 10px; //RufUpgrade css change
  }
}
client-portal-cp-main-community {
  .mat-mdc-card {
    padding: 30px;
    .mat-mdc-tab {
      flex-grow: 0 !important;
    }
  }
  .ruf-dialog-container {
    padding: 1.4375rem 1.5rem 1.25rem 1.5rem;
  }
}
client-portal-cp-main-invoices-page {
  .mat-mdc-card {
    padding: 30px;
    .mat-mdc-form-field-subscript-wrapper {
      display: none;
    }
  }
}
client-portal-cp-main-reports {
  .mat-mdc-card {
    padding: 30px;
    .mat-mdc-text-field-wrapper {
      margin-top: 0 !important;
    }
    .mat-mdc-form-field-subscript-wrapper {
      display: none;
    }
  }
  button.mat-stroked-button.mat-accent {
    color: #fff;
    margin-left: 10px;
  }

  .mat-mdc-text-field-wrapper .mdc-text-field__input,
  .mat-mdc-text-field-wrapper .mat-mdc-select {
    //padding: 0 10px;
  }
}

.mat-mdc-tab-labels .mdc-tab-indicator__content.mdc-tab-indicator__content--underline {
  border-color: #71c954 !important;
}
#main-section-wrapper {
  ruf-app-canvas {
    background: #f9f5f1;
    mat-card {
      &.table-scroll {
        .mat-button-toggle-group {
          background: #f9f5f1;

          .mat-button-toggle:not(.mat-button-toggle-checked) {
            background: #f9f5f1;
          }

          .mat-button-toggle.mat-button-toggle-checked {
            border-color: #dfdfdf !important;
          }
        }
      }

      .button-text {
       // margin-bottom: 15px; // Fix for alignment for labels
      }

      mat-form-field {
        .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex {
          //padding: 5px 0;
        }

        .mat-mdc-form-field-subscript-wrapper {
          display: none;
        }
      }
      /*---Tab label width fix---*/
      mat-tab-header{
        .mat-mdc-tab-list{
           display: flex;
           .mat-mdc-tab-labels{flex:0 0 auto !important;}
        }
      }
    }
  }
}
client-portal-cp-bulletins-view {
  mat-card {
    .mat-button-toggle-group {
      background: #f9f5f1 !important;
      mat-button-toggle:first-child{background: #f9f5f1 !important;}
      mat-button-toggle:last-child{border-color: #dfdfdf !important;}
    }
  }
 }
client-portal-cp-pinned-dialog .ruf-dialog-header-bar .mat-mdc-icon-button {
  top: -20px !important;
  position: absolute;
}
  //----------pagination-design ---------------//
  /*client-portal-cp-main-entity-page,
  client-portal-cp-main-administration,
  client-portal-cp-reports-rules-administration,
  client-portal-cp-report-administration-card,
  client-portal-cp-invoice-notification-page,
  client-portal-cp-download-logs,
  client-portal-cp-main-user-onboarding-page,
  client-portal-cp-invoice-notification-page,
  client-portal-cp-product-onboarding,
  client-portal-cp-onboarding-status-tab {}*/
.pagination-design {
  margin: 25px 0;
  position: relative;

  mat-paginator {
    mat-form-field {
      .mat-mdc-form-field-flex {
        .mdc-notched-outline {
          margin: 0;
        }
      }
    }
  }

  .go-to-container {
    bottom: -1px;
    left: 368px;

    mat-form-field {
      .mat-mdc-text-field-wrapper {
        margin: 0 !important;

        .mat-mdc-form-field-flex {
          /*padding: 0 !important;*/
          .mdc-text-field__input {
            padding: 0 !important;
            text-align: center;
            margin: 0 !important;
          }
        }
      }

      .mat-mdc-form-field-subscript-wrapper {
        display: none;
      }
    }
  }
}
  //---------Popup CSS-------------//
  .mdc-dialog__container {
    forgot-entity-success ruf-dialog-panel {padding: 1.25rem;}
    ruf-dialog-panel {
      .ruf-dialog-container {
        padding: 1.25rem;

        .mdc-dialog__title {
          padding: 0 !important;

          &::before {
            display: none;
          }
        }

        .mat-mdc-dialog-content ruf-dialog-content {
          > mat-card {
            border: 0;
          }

          mat-form-field:not(.mat-form-field-invalid) {
            .mat-mdc-form-field-subscript-wrapper {
              display: none;
            }
          }
          .ruf-form-field-textarea textarea.mat-mdc-input-element{border: 1px solid #dfdfdf !important; border-radius: 4px; padding: 12px;}
          #view_area{
           .ruf-form-field-textarea textarea.mat-mdc-input-element{border: 0 !important;}
           .ruf-margin-top-medium a.card-description{display: flex; padding: 20px 0;}
           .ruf-boxed-input-wrapper.ruf-margin-bottom-medium.ruf-margin{
               a,span{display: flex; align-items: center;}
           }
          }
        }
      }
    }
  }
  client-portal-cp-dialogue-box{
    mat-form-field .mat-mdc-text-field-wrapper{ background-color: transparent !important; }
    .ruf-dialog-container .ruf-dialog-content{padding: 0 20px !important;}
  }
  //----------Attachment Icon--------//
client-portal-cp-create-edit-report-admin-card .mat-icon.mat-ligature-font[fontIcon]::before, client-portal-cp-add-edit-compliance-reporting .mat-icon.mat-ligature-font[fontIcon]::before {
  content: attr(fontIcon);
  content: "\e97d";
}

  .ruf-dynamic-segment {
    max-width: 35%; //RufUpgrade css change for admin top segment width
  }

  .hide_show_next_contact_date {
    visibility: hidden !important;
  }
/*------------Form Input Vertical align-----------*/
mat-form-field {
  /*.mat-mdc-text-field-wrapper {
    .mat-mdc-form-field-flex {
      padding: 5px 0;
    }
  }*/
  .mat-mdc-text-field-wrapper {margin-top: 0 !important;}
  .mat-mdc-form-field-subscript-wrapper{display: none;}
}
/*-------Autofilled background Fix-------------*/
input:is(:-webkit-autofill, :autofill) {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}
input.mat-mdc-input-element {
  text-transform: none;
}
/*------------fis-icon-attachment fix---------*/
mat-icon.fis-icon-attachment:before {
  content: "\e97d";
}
/*----------Error font Fix------------*/
mat-mdc-form-field mat-error.mat-mdc-form-field-error {
  color: #ff1f3e !important;
  font-size: 12px !important;
  margin: 5px 0 !important;
}
// ---------responsive-------------
@media screen and (max-width: 767px) {
  /*.go-to-container {
    left: 370px;
    bottom: 7px;
  }*/

  .mat-mdc-paginator-container {
    flex-wrap: nowrap !important;
  }

  .ruf-datatable-footer {
    width: max-content;
  }

  .button-ellipse {
    max-width: 100%;
    white-space: nowrap !important;
    overflow: hidden !important;
    text-overflow: ellipsis;
  }

  /*.pagination-design {
    .mat-mdc-paginator-navigation-next {
      left: 262px !important;
      top: -3px !important;
    }

    .mat-mdc-paginator-navigation-previous {
      top: -3px !important;
      left: 140px !important;
    }
  }*/
}
.hide_show_next_contact_date {
  visibility: hidden !important;
}





// Adding global style for Mat Select Field



// .mat-mdc-form-field-flex {

//   padding: 5px 0;

// }



.mat-mdc-form-field.mat-form-field-appearance-outline[fisStyle]:not(.ruf-form-field-textarea):not(.ruf-boxed-input-with-chips) .mdc-text-field.mdc-text-field--outlined .mat-mdc-form-field-flex .mat-mdc-floating-label, .mat-mdc-form-field.mat-form-field-appearance-outline[boxStyle]:not(.ruf-form-field-textarea):not(.ruf-boxed-input-with-chips) .mdc-text-field.mdc-text-field--outlined .mat-mdc-form-field-flex .mat-mdc-floating-label {
  top: 13px;
}



.mat-mdc-tab-group.mat-mdc-tab-group-stretch-tabs > .mat-mdc-tab-header .mat-mdc-tab {
  flex-grow: unset;
}



.mat-mdc-tab-header-pagination {
  border: none !important;
}



.mat-mdc-card-subtitle {
  line-height: 1 !important;
}



.bulletin-create-space {

  .mat-mdc-form-field.mat-form-field-appearance-outline[fisStyle] .mdc-text-field.mdc-text-field--outlined, .mat-mdc-form-field.mat-form-field-appearance-outline[boxStyle] .mdc-text-field.mdc-text-field--outlined {
    background-color: #fff !important;
  }
}



.mat-form-field-appearance-outline:not(.mat-form-field-appearance-legacy)[boxStyle] .mdc-notched-outline, .mat-form-field-appearance-outline:not(.mat-form-field-appearance-legacy)[fisStyle] .mdc-notched-outline {
  min-height: 36px !important;
}



.mat-mdc-form-field.mat-form-field-appearance-outline[fisStyle]:not(.ruf-form-field-textarea):not(.ruf-boxed-input-with-chips) .mdc-text-field.mdc-text-field--outlined .mat-mdc-form-field-infix, .mat-mdc-form-field.mat-form-field-appearance-outline[boxStyle]:not(.ruf-form-field-textarea):not(.ruf-boxed-input-with-chips) .mdc-text-field.mdc-text-field--outlined .mat-mdc-form-field-infix {
  padding: 0 !important;
}



// ==============================================================================================



// Avoid the Tabbing to grow to full width



.mat-mdc-tab-group.mat-mdc-tab-group-stretch-tabs > .mat-mdc-tab-header .mat-mdc-tab {
  flex-grow: unset !important;
}



.mat-mdc-tab-body.mat-mdc-tab-body-active {
  overflow: inherit !important;
}



.mat-mdc-tab-group[fisStyle] .mat-mdc-tab-header {
  border-bottom: 0 !important;
}



// ==============================================================================================
/* Changes By UI Team - Global Style for Tables and overwriting Material Colors*/
.mat-mdc-table[fisStyle] .mat-sort-header:not(.mat-sort-header-disabled):not([disabled]), .mdc-data-table__table[fisStyle] .mdc-data-table__header-row .mdc-data-table__header-cell {
  background-color: #003B4D !important;
  border-bottom: none !important;
}

.mat-mdc-table[fisStyle].ruf-sort-icon-solid-arrow .mat-sort-header-arrow {
  margin-top: 0 !important;
}

.mat-mdc-table[fisStyle] .mat-sort-header .mat-sort-header-container {
  align-items: center !important;
}
.bulletin-create-space .section-accord-bulletin .custom-toggle p {
  display: flex;
  align-items: center;
}
.search-matpaginator-main .mat-mdc-paginator-outer-container .mat-mdc-paginator-container .mat-mdc-paginator-range-actions .mat-mdc-paginator-range-label {
  margin-right: 0 !important;
  margin-left: 60px !important;
}
.mat-mdc-paginator[fisStyle] .mat-mdc-button-base {
  --ruf-button-icon-font-size: 24px !important;
}
.mat-mdc-paginator-navigation-next {
  margin-left: 20px !important;
}
.btndownloadalign {
  align-self: center !important;
}
.table-pagination {
  display: flex;
  margin-top: 0;
  border-top: 1px solid #ddd;
  padding-top: 20px;
}
.table-pagination .Download-button-mat {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: flex-end;
  margin: 0 !important;
}
.accordian-section accordian-tabs {

  mat-expansion-panel {
    outline: none !important;
    border-radius: 0 !important;

    .mat-expansion-panel-header {

      .mat-expansion-indicator {
        background: none !important;
      }
    }
  }
}
.mat-mdc-select-arrow {
  top: 1.5px;
  left: 2px;
}
/*.custom-autocomplete-field .mat-mdc-form-field.single-select {

  .mat-mdc-text-field-wrapper {

    .mat-mdc-form-field-infix {
      padding-left: 12px !important;
      padding-right: 12px !important;
    }
  }
}*/
.admin-template-fields .create-text-icon {
  font-weight: 700;
}
.mat-mdc-table[fisStyle] {

  .mat-mdc-header-cell, .mat-mdc-cell {

    &.th-center {
      justify-content: center;
    }
  }
}
.cdk-overlay-pane mat-dialog-container.mat-mdc-dialog-container .ruf-dialog-panel.fis-override .ruf-dialog-container.ruf-dialog-separator-enabled .ruf-dialog-header:not(.ruf-header-empty) .ruf-dialog-header-bar {
  /*border-bottom: none !important;
  padding-bottom: 0 !important;*/ /*CLNTSVC-16167: MYUAT || UI issue on Emulation pop-up for Internal User*/
}
client-portal-cp-pinned-dialog .ruf-dialog-container .ruf-dialog-header.mat-mdc-dialog-title .ruf-dialog-header-bar .mat-mdc-icon-button {
  position: absolute;
  top: -10px !important;
  right: -10px !important;
}
.cdk-overlay-pane mat-dialog-container.mat-mdc-dialog-container .ruf-dialog-panel.fis-override .ruf-dialog-container .ruf-dialog-content, mat-dialog-container.mat-mdc-dialog-container .mat-mdc-dialog-surface .ruf-dialog-panel .ruf-dialog-container .ruf-dialog-content ruf-dialog-content {
  overflow: initial !important;
  max-height: 100% !important;
}
.mdc-list-item__primary-text {

  & + .mat-pseudo-checkbox.mat-mdc-option-pseudo-checkbox {
    display: none;
  }
}

/*CSS for Minimize Issue Fix*/
.ruf-popover-menu.fis-override.ruf-dropdown-panel-container.ruf-panel-left-arrow-up{display: none;}

/* Changes for Global Style for Tables pagination */
/*.entire-mat-page-btn {
  justify-content: flex-start !important;
  gap: 60px;

  .gotojump {
    position: relative !important;
    left: 0 !important;
    margin: 0 !important;
    width: 60px;

    .mat-mdc-text-field-wrapper {
      margin: 0 !important;
    }

    .mat-mdc-form-field-subscript-wrapper.mat-mdc-form-field-bottom-align {
      display: none;
    }
  }
}

@media screen and (max-width: 767px) {
  .mat-mdc-paginator-range-label {
    min-width: 70px !important;
  }
}*/


// ---------Global responsive-------------
@media screen and (max-width: 767px) {
    /*Global CSS Change for main-section-wrapper*/
   #main-section-wrapper{
    div:first-child.ruf-margin-large.ruf-padding-large.ruf-margin.ruf-padding {
      margin: 0 !important;
    }
    ruf-app-canvas mat-card{padding: 15px;}
   }

    /*Global Responsive CSS form Pagination*/
  .pagination-design {
    .mat-mdc-paginator-container{gap: 15px;}
    .mat-mdc-paginator-navigation-next{margin-left: 50px !important;}
    .mat-mdc-paginator-navigation-previous, .mat-mdc-paginator-navigation-next{position: static !important;}
    .go-to-container {left: 65% !important; top: -13%;}
  }
}
