/* Importing the prebuilt color theme called "fis-default" */
//@import 'node_modules/@ruf/theming/styles/theming/typography';
@use './main-product-page/components/resources-tools/components/tab-loyality-resources/component/cp-loyalty-datatable.theme' as cp-loyalty-datatable;
@mixin loyalty-all-theme($theme, $config: null) {
  @include cp-loyalty-datatable.loyalty-datatable-theme($theme);
  @include loyalty-typography($config);
}
@mixin loyalty-typography($theme, $config: ruf-typography-config()) {
  // include your typography mixins below
  //@include tickets datatable typography

  @include cp-loyalty-datatable.loyalty-datatable-typography($config);
  //@@TYPOGRAPHY
}
