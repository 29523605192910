//@import 'node_modules/@ruf/theming/styles/theming/theming';
@use '@ruf/shell' as ruf;

// a convenience mixin that aggregates all of your component theme mixins
@mixin cp-user-onboarding-tab-theme($theme) {
  $background: map-get($theme, background);
  $foreground: map-get($theme, foreground);
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $emphasis: map-get($theme, emphasis);
  $warn: map-get($theme, warn);
  $error: map-get($theme, error);

  client-portal-cp-user-onboarding-tab {
    p {
      color: ruf.ruf-color($primary);
    }
    .star-color{
      color: ruf.ruf-color($error);
  }
  .view-more-arrow {
    transition: all 0.4s ease-in-out;
    color: ruf.ruf-color($accent);
    cursor: pointer;
  }
  .view-more {
    a {
      color: #015b7e;
    }
    &:hover {
      a {
        color: #285bc5;
      }
      .view-more-arrow {
        transform: translate(5px);
      }
    }
  }
  }
}

@mixin cp-user-onboarding-tab-typography($config) {
  client-portal-cp-user-onboarding-tab {
    p {
      // @include ruf-typography-level-to-styles($config, 'title');
    }
  }
}
