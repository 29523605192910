/* Importing the prebuilt color theme called "fis-default" */
@use "@ruf/shell" as ruf;
@use "@ruf/widgets" as ruf-widgets;
@use "@ruf/prebuilt-themes/fis-default" as ruf-prebuilt-themes;
// Importing the RUF shell structural styles
@use "@ruf/shell/styles/index" as ruf-shell-style-index;

/*@import "node_modules/@ruf/theming/styles/theming/theming";
@import "node_modules/@ruf/material/src/card/card.theme";
@import "node_modules/@ruf/material/src/slide-toggle/slide-toggle.theme";
@import "node_modules/@ruf/ngx-datatable/styles/all-theme.scss";
@import "node_modules/@ruf/ngx-datatable/styles/_ngx-datatable.structure.scss";*/
@import "node_modules/@ruf/material/src/slide-toggle/slide-toggle.theme";

// a convenience mixin that aggregates all of your component theme mixins
@mixin example-theme($theme) {
  @include ruf-mat-boxed-input-theme($theme);
  @include ruf-mat-slide-toggle-theme($theme);
}

cp-main-user-Onboarding-page {
  .ngx-datatable[fisStyle] .datatable-row-wrapper .datatable-body-cell .datatable-body-cell-label .datatable-icon-right,
  .ngx-datatable[fisStyle] .datatable-row-wrapper .datatable-body-cell .datatable-body-cell-label .datatable-icon-down {
    font-size: 1.125rem;
  }

  .ngx-datatable[fisStyle] .datatable-row-wrapper .datatable-body-cell .datatable-icon-right,
  .ngx-datatable[fisStyle] .datatable-row-wrapper .datatable-body-cell .datatable-icon-down {
    color: rgba(var(--ruf-foreground-text, 1, 40, 52), 1);
  }

  .ngx-datatable[fisStyle] .datatable-row-wrapper .datatable-body-cell .datatable-body-cell-label .datatable-icon-down::before {
    transform: rotate(180deg);
  }

  .ngx-datatable[fisStyle] .datatable-icon-up::before,
  .ngx-datatable[fisStyle] .datatable-icon-down::before {
    position: absolute;
  }

  .ngx-datatable[fisStyle] .datatable-icon-left::before,
  .ngx-datatable[fisStyle] .datatable-icon-right::before,
  .ngx-datatable[fisStyle] .datatable-icon-down::before,
  .ngx-datatable[fisStyle] .datatable-icon-up::before {
    content: "\e910";
    display: inline-block;
  }

  .ngx-datatable[fisStyle] .datatable-icon-right::before,
  .ngx-datatable[fisStyle] .datatable-icon-skip::before,
  .ngx-datatable[fisStyle] .datatable-icon-prev::before,
  .ngx-datatable[fisStyle] .datatable-icon-left::before,
  .ngx-datatable[fisStyle] .datatable-icon-down::before,
  .ngx-datatable[fisStyle] .datatable-icon-up::before {
    font-family: "fisfont";
    font-style: normal;
  }

  .admin-ticket-template-table {
    .ngx-datatable .datatable-header .datatable-header-cell {
      .datatable-header-cell-template-wrap {
        display: flex;
        align-items: center;
        gap: 4px;
      }

      .sort-btn {
        height: 14px;
      }
    }
  }
  // ************* Change style of switch buttons ************
  // .mat-mdc-slide-toggle[fisStyle] .mat-mdc-slide-toggle-bar,
  // .mat-mdc-slide-toggle[fisStyle].mat-accent.mat-checked:not(.mat-disabled) .mat-mdc-slide-toggle-bar {
  //   height: 20px;
  //   width: 38px;
  //   border-radius: 20px;
  // }
  // .mat-mdc-slide-toggle[fisStyle].mat-accent.mat-checked:not(.mat-disabled) .mat-mdc-slide-toggle-bar {
  //   background-color: #4bcd3e;
  // }
  // .mat-mdc-slide-toggle[fisStyle]:not(.mat-checked) {
  //   .mat-mdc-slide-toggle-bar {
  //     background-color: #ccc;
  //   }
  //   .mat-mdc-slide-toggle-thumb {
  //     background-color: #fff;
  //   }
  // }
  // .mat-mdc-slide-toggle-thumb-container {
  //   top: 2px !important;
  //   left: 3px !important;
  //   .mat-mdc-slide-toggle-thumb {
  //     height: 16px;
  //     width: 16px;
  //     border-radius: 20px;
  //   }
  // }
  // .mat-mdc-slide-toggle.mat-checked {
  //   .mat-mdc-slide-toggle-thumb-container {
  //     .mat-mdc-slide-toggle-thumb {
  //       background-color: #fff;
  //     }
  //   }
  // }
  // ************* Ends change style of switch buttons ************

  .support-area-form {
    mat-form-field {
      .mat-form-field-wrapper {
        padding-bottom: 0 !important;
        margin-bottom: 0 !important;
      }
    }
  }

  .custom-select-input {
    .mat-form-field-wrapper {
      padding-bottom: 0 !important;
    }
  }

  .list-builder-parent {
    .mat-mdc-form-field {
      .mat-form-field-outline {
        border: none;

        .mat-form-field-outline-start,
        .mat-form-field-outline-end,
        .mat-form-field-outline-gap {
          border: none;
        }
      }
    }
  }

  .mat-option-text {
    font-weight: 600;
  }
  // Dialog Theme
  .ruf-dialog-container {
    .ruf-dialog-header.mat-dialog-title .ruf-dialog-header-bar .dialog-header {
      font-size: 22px;
    }
  }

  .search-user-box {
    .multi-select-combo-box-example-form {
      .mat-chip-list-wrapper {
        .mat-input-element {
          position: relative;
          top: 0;
        }
      }
    }

    .mat-standard-chip {
      border-width: 2px !important;
      border-radius: 15px !important;
    }
  }

  .mat-autocomplete-panel {
    .mat-option {
      .mat-option-text {
        display: flex !important;
        align-items: center;
        gap: 8px;
      }
    }

    .mat-pseudo-checkbox {
      border-color: #4bcd3e;
    }

    .mat-pseudo-checkbox-checked,
    .mat-pseudo-checkbox-indeterminate {
      border-color: transparent;
      background: #4bcd3e;
    }
  }

  .mat-mdc-table[fisStyle] .mat-mdc-header-row .mat-header-cell:hover:not(.mat-mdc-table-sticky) {
    background-color: transparent;
  }

  .mat-tab-group {
    .mat-tab-header {
      border-bottom: none;

      .mat-tab-label-container {
        .mat-ink-bar {
          background-color: #4bcd3e !important;
        }
      }
    }
  }

  .ruf-list-builder-inner-container {
    .mat-mdc-form-field {
      // width: 100%;
      .mat-form-field-wrapper {
        padding: 0 !important;
      }
    }
  }

  .list-builder-full {
    .ruf-list-builder {
      .ruf-list-builder-container {
        @media screen and (max-width: 767px) {
          .ruf-list-builder-source,
          .ruf-list-builder-target,
          div.ruf-list-builder-floater {
            width: 100% !important;
          }
        }
      }
    }
  }
  // Radio Button Code

  .mat-radio-button.mat-primary .mat-radio-inner-circle,
  .mat-radio-button.mat-primary .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple),
  .mat-radio-button.mat-primary.mat-radio-checked .mat-radio-persistent-ripple,
  .mat-radio-button.mat-primary:active .mat-radio-persistent-ripple {
    background-color: #4bcd3e;
  }

  .mat-radio-button.mat-primary.mat-radio-checked .mat-radio-outer-circle {
    border-color: #4bcd3e;
  }
  // Button Hover
  // .mat-raised-button[fisStyle].mat-accent:not([disabled]):active,
  // .mat-raised-button[fisStyle].mat-accent:not([disabled]).cdk-keyboard-focused,
  // .mat-raised-button[fisStyle].mat-accent:not([disabled]).cdk-program-focused,
  // .mat-flat-button[fisStyle].mat-accent:not([disabled]):active,
  // .mat-flat-button[fisStyle].mat-accent:not([disabled]).cdk-keyboard-focused,
  // .mat-flat-button[fisStyle].mat-accent:not([disabled]).cdk-program-focused {
  //   color: #fff;
  // }
  // Hide Data Table Header on Responsive

  .mat-mdc-table[fisStyle] {
    .mat-mdc-header-row {
      @media (max-width: 990px) {
        display: none !important;
      }
    }
  }
  // Responsive Code

  @media (max-width: 1170px) {
    .ngx-datatable {
      .datatable-header,
      .datatable-body-cell-label {
        font-size: 12px;
      }
    }
  }

  @media (max-width: 1023px) {
    .admin-ticket-template-table {
      .ngx-datatable.fixed-header .datatable-header .datatable-header-inner {
        .datatable-header-cell {
          &:nth-last-child(1),
          &:nth-last-child(2) {
            display: none;
          }

          &:nth-last-child(4) {
            min-width: 50%;
          }

          &:nth-last-child(3) {
            min-width: 23%;
          }
        }
      }

      .ngx-datatable.fixed-row .datatable-scroll .datatable-body-row {
        .datatable-body-cell {
          &:nth-last-child(1),
          &:nth-last-child(2) {
            display: none;
          }

          &:nth-last-child(4) {
            min-width: 50%;
          }

          &:nth-last-child(3) {
            min-width: 23%;
          }
        }
      }
    }
  }
  // Style for Tables

  .mat-mdc-table[fisStyle] {
    .mat-mdc-header-row {
      .mat-mdc-header-cell {
        &:nth-child(1) {
          max-width: 250px;
          margin-left: 5px;
        }

        &:nth-child(2) {
          max-width: 150px;
        }

        &:nth-child(3) {
          min-width: 350px;
        }

        &:nth-child(5) {
          max-width: 110px;
          text-align: center;
        }
      }

      @media (max-width: 1023px) {
        .mat-mdc-header-cell {
          &:nth-child(1),
          &:nth-child(2),
          &:nth-child(3),
          &:nth-child(4),
          &:nth-child(5) {
            max-width: 100%;
            min-width: 1px;
            text-align: left;
          }
        }
      }

      @media screen and (max-width: 767px) {
        display: none;
      }
    }

    &.ruf-striped-rows {
      .mat-mdc-row {
        border-bottom: none;

        .mat-mdc-cell {
          border-bottom: none;

          &:nth-child(1) {
            max-width: 250px;
          }

          &:nth-child(2) {
            max-width: 150px;
          }

          &:nth-child(3) {
            min-width: 350px;
          }

          &:nth-child(5) {
            max-width: 110px;
            //text-align: center;
          }
        }

        @media (max-width: 1023px) {
          .mat-mdc-cell {
            &:nth-child(1),
            &:nth-child(2),
            &:nth-child(3),
            &:nth-child(4),
            &:nth-child(5) {
              max-width: 100%;
              min-width: 1px;
              text-align: left;
            }
          }
        }
      }
    }

    &.custom-table {
      .mat-mdc-header-row {
        .mat-mdc-header-cell {
          &.w-100 {
            max-width: 100px;
            min-width: 1px;
          }

          &.w-150 {
            max-width: 150px;
            min-width: 1px;
          }

          &.w-590 {
            max-width: 590px;
            min-width: 1px;
          }

          &.w-240 {
            max-width: 240px;
            min-width: 1px;
          }

          &.th-center {
            justify-content: center;
          }
        }
      }

      .mat-mdc-row {
        .mat-mdc-cell {
          &.w-100 {
            max-width: 100px;
            min-width: 1px;
          }

          &.w-150 {
            max-width: 150px;
            min-width: 1px;
          }

          &.w-590 {
            max-width: 590px;
            min-width: 1px;
          }

          &.w-240 {
            max-width: 240px;
            min-width: 1px;
          }

          .th-center {
            justify-content: center;
          }
        }
      }
    }
  }

  @media only screen and (min-width: 768px) {
    .mat-paginator .mat-paginator-navigation-next {
      right: -60px;
    }

    .mat-paginator-range-actions {
      .mat-paginator-navigation-previous {
        position: absolute;
        right: 100px;
      }

      .mat-paginator-navigation-next {
        position: absolute;
        right: 10px;
      }
    }
    /*.filter-mattable {
      margin-bottom: -15px;
    }*/ // not required in upgrade code
  }

  .table-pagination-block {
    margin-top: 30px;
    padding-top: 25px;
    border-top: 1px solid #b3c1c7;
    width: 100%;
    display: flex;
    flex: auto;
    justify-content: space-between;

    .search-matpaginator-main {
      width: 100%;
      // border: 1px solid red;
    }

    .mat-mdc-paginator-container {
      justify-content: flex-start;
      padding-left: 0;
      overflow: hidden;
    }
  }

  @media only screen and (max-width: 767px) {
    .entire-mat-page-btn {
      position: relative;
    }

    .paginator-rows {
      margin-bottom: 20px;
    }

    .search-matpaginator-main {
      width: calc(100% - 85px);
      margin: 0;
      float: left;
      display: inline-block;

      .mat-mdc-paginator-page-size-select {
        // width: 36px;
        width: 65px;
        margin: 14px 0 0 0;
      }

      .mat-paginator-page-size {
        width: 30%;
        margin: 0;
        padding-right: 12px;
      }

      .mat-paginator-range-actions {
        width: 70%;
        padding-left: 40px;
      }
    }

    .gotojump .mat-form-field-wrapper {
      width: 45px;
      margin-left: 24px !important;
      margin-top: 16px !important;
      margin-right: 0 !important;
    }

    .mat-paginator-outer-container {
      width: 100%;
    }

    .mat-paginator[fisStyle] .mat-paginator-navigation-previous {
      left: 80px;
      top: -6px;
      // position: absolute;
      display: none;
    }

    .mat-paginator[fisStyle] .mat-paginator-navigation-next {
      right: -60px;
      top: -6px;
      // position: absolute;
      display: none;
    }

    user-onboarding-editable-datatable .mat-mdc-table.cp-admin-custom-datatable.ruf-striped-rows .mat-mdc-row .mat-mdc-cell {
      width: 100%;
      display: flex;
      &.mat-column-MoreActions > div{
        width: 100%;
        div{flex: 0 0 25% !important;}
      }
    }
  }
  /* =============== Single code for all Admin DataTables ========================== */

  .edit-columns.custom-columns-edit-col {
    margin-top: 0 !important;
    margin-bottom: 10px;
  }

  .mat-mdc-table[fisStyle] {
    .mat-mdc-header-row {
      background: #003b4d;
      padding: 5px 0;

      .mat-mdc-header-cell {
        color: #fff;
        border-bottom: none !important;
        font-weight: 400;
        padding: 0.6rem 15px;

        &:nth-child(1) {
          max-width: 250px;
        }

        &:nth-child(2) {
          max-width: 250px;
        }

        &:nth-child(3),
        &:nth-child(4),
        &:nth-child(5) {
          min-width: 120px;
        }

        &:nth-child(6) {
          min-width: 180px;
        }
      }

      @media screen and (max-width: 1023px) {
        .mat-mdc-header-cell {
          &:nth-child(1),
          &:nth-child(2),
          &:nth-child(3),
          &:nth-child(4),
          &:nth-child(5) {
            max-width: 100%;
            min-width: 1px;
            text-align: left;
          }
        }
      }

      @media screen and (max-width: 767px) {
        display: none;
      }
    }

    &.ruf-striped-rows {
      .mat-mdc-row {
        border-bottom: none;

        .mat-mdc-cell {
          border-bottom: none;

          &:nth-child(1) {
            max-width: 250px;
          }

          &:nth-child(2) {
            max-width: 250px;
          }

          &:nth-child(3),
          &:nth-child(4),
          &:nth-child(5) {
            min-width: 120px;
          }

          &:nth-child(6) {
            min-width: 180px;
          }
        }

        .headerIcon {
          color: #012834;
          margin-left: 30%;

          mat-icon {
            color: #012834;
          }
        }

        @media (max-width: 1023px) {
          .mat-mdc-cell {
            &:nth-child(1),
            &:nth-child(2),
            &:nth-child(3),
            &:nth-child(4),
            &:nth-child(5) {
              max-width: 100%;
              min-width: 1px;
              text-align: left;
            }
          }
        }
      }
    }

    @media screen and (max-width: 767px) {
      .mat-header-cell,
      .mat-mdc-cell {
        max-width: 100% !important;
      }

      .th-center {
        justify-content: flex-start;
      }
    }
  }

  .ruf-dropdown-panel-content {
    min-width: 150px;
    padding: 1rem;

    .dropdown-list {
      padding: 0;
      margin: 0;
      display: flex;
      flex-direction: column;
      gap: 12px;

      li {
        list-style: none;

        a {
          color: #012834;
          font-size: 13px;
          font-weight: 600;
        }
      }
    }
  }

  .mobile-label {
    display: none;
  }

  .entire-mat-page-btn {
    display: flex;
    width: 70%;
    justify-content: space-between;

    .gotojump {
      width: 44px;
      min-width: 1px;
      margin-left: 0;
      margin-top: 7px;
      position: absolute !important;
      left: 50.5%;
    }
  }

  .custom-table-pagination {
    .mat-form-field-type-mat-select {
      min-width: 1px;
    }

    .gotojump {
      .mat-form-field-wrapper {
        .mat-form-field-infix {
          padding: 11px 0 !important;
        }
      }

      .mat-select-arrow-wrapper .mat-select-arrow {
        margin-top: -6px !important;
      }
    }
  }

  .search-matpaginator-main {
    .mat-paginator-outer-container {
      .mat-mdc-paginator-container {
        justify-content: flex-start;
        padding-left: 0;

        .mat-paginator-range-actions {
          .mat-paginator-range-label {
            margin-left: 110px;
          }

          button.mat-focus-indicator {
            position: absolute;
            left: 45.8%;

            &.mat-paginator-navigation-next {
              left: 54.5%;
            }

            &.mat-paginator-navigation-first {
              left: 43%;
            }

            &.mat-paginator-navigation-last {
              left: 57.5%;
            }

            @media screen and (max-width: 767px) {
              display: none;
            }
          }
        }
      }
    }
  }

  .btndownloadalign {
    text-align: right;
    flex: 1;

    p {
      mat-icon {
        color: #4bcd3e;
        margin-left: 3px;
      }
    }
  }

  .mat-mdc-table {
    .mat-mdc-row {
      cursor: pointer;
    }
  }

  .paginator-rows {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .table-global-search {
      .mat-mdc-form-field {
        .mat-form-field-wrapper {
          margin: 0;
          padding: 0 !important;
        }
      }
    }

    @media screen and (max-width: 767px) {
      flex-direction: column;
      margin: 15px 0;
      gap: 15px;

      .strategy-filter-mattable {
        width: 100%;

        .mat-mdc-form-field {
          width: 100%;

          .mat-form-field-infix {
            top: 0;
          }
        }
      }
    }
  }

  .mat-mdc-paginator-page-size-select.mat-form-field-appearance-outline {
    .mat-form-field-wrapper {
      margin: 0;
      padding: 0 !important;
    }
  }

  @media only screen and (max-width: 991px) {
    .mat-mdc-table {
      border: 0;
      vertical-align: middle;
    }

    .mat-mdc-table caption {
      font-size: 1em;
    }

    .mat-mdc-table .mat-mdc-header-cell {
      border: 10px solid;
      clip: rect(0 0 0 0);
      height: 1px;
      margin: -1px;
      padding: 0;
      position: absolute;
      width: 1px;
    }

    .mat-mdc-table .mat-mdc-header-row {
      padding: 0;
      border: none;
    }

    .filter-mattable {
      display: none;
    }

    .mobile-label {
      width: 40%;
      display: inline-block;
      font-weight: bold;
      padding-right: 10px;
    }

    .mat-mdc-table .mat-mdc-row {
      display: block;
      padding: 4px 0 0 4px;
    }

    .d-none {
      display: none !important;
    }

    .mat-mdc-table .mat-mdc-row:nth-child(even) {
      background: #fff;
    }

    .mat-mdc-table .mat-mdc-row:nth-child(odd) {
      background: #f5f5f5;
    }

    .mat-mdc-table .mat-mdc-cell {
      display: block;
      font-size: 1em;
      text-align: left;
      line-height: 16px;
      padding: 4px 0;
    }

    .mat-mdc-table .mat-mdc-cell:before {
      /*
    * aria-label has no advantage, it won't be read inside a table
    content: attr(aria-label);
    */
      content: attr(data-label);
      float: left;
      text-transform: uppercase;
      font-weight: normal;
      font-size: 0.85em;
    }

    .mat-mdc-table .mat-mdc-cell:last-child {
      border-bottom: 0;
    }

    .mat-mdc-table .mat-mdc-cell:first-child {
      margin-top: 4%;
    }
  }

  .no-data {
    padding: 1.5em;
    text-align: center;
    display: flex;
    align-items: center;
    font-size: 20px;
    justify-content: center;

    mat-icon {
      margin-right: 6px;
    }
  }

  .tablabel {
    margin-right: 5px;
  }

  .table-pagination {
    align-items: center;
    display: flex;
    margin-top: 15px;
    justify-content: space-between;

    .search-matpaginator-main {
      .mat-mdc-paginator-outer-container {
        .mat-mdc-paginator-container {
          justify-content: flex-start;
          padding-left: 0;

          .mat-mdc-paginator-range-actions {
            .mat-mdc-paginator-range-label {
              margin-left: 110px;
            }

            button {
              position: absolute;
              left: 45.8%;

              &.mat-mdc-paginator-navigation-next {
                left: 54.5%;
              }

              &.mat-mdc-paginator-navigation-first {
                left: 43%;
              }

              &.mat-mdc-paginator-navigation-last {
                left: 57.5%;
              }

              @media screen and (max-width: 767px) {
                display: none;
              }
            }
          }
        }
      }
    }

    .entire-mat-page-btn {
      display: flex;
      width: 70%;
      justify-content: space-between;
      align-items: center;

      .gotojump {
        width: 44px;
        min-width: 1px;
        margin-left: 0;
        margin-top: 7px;
        position: absolute !important;
        left: 49.5%;
      }

      .mat-mdc-form-field {
        .mat-mdc-text-field-wrapper {
          .mdc-notched-outline {
            //min-height: 2.25rem;
          }
        }
      }

      mat-form-field.mat-mdc-form-field.gotojump {
        width: 60px;
      }
    }

    @media (max-width: 960px) {
      flex-direction: column;
      gap: 15px;
      .entire-mat-page-btn {
        width: 100%;
        flex-direction: column;

        .search-matpaginator-main {
          .mat-paginator-outer-container .mat-mdc-paginator-container {
            padding: 0;

            .mat-paginator-range-actions {
              // display: block;
            }
          }

          @media screen and (max-width: 767px) {
            max-width: 70%;
          }
        }

        .mat-mdc-form-field.gotojump {
          /*max-width: 45px;*/
          margin: 0 auto;
          left: auto;
          right: 40px;
          top: -5px;

          .mat-form-field-wrapper {
            padding-bottom: 0;
            margin-bottom: 0;
          }
        }
      }
    }

    .Download-button-mat {
      font-weight: 600;
    }
  }

  .manageUserProfileTab {
    .table-pagination {
      .search-matpaginator-main {
        .mat-mdc-paginator-outer-container {
          .mat-mdc-paginator-container {
            .mat-mdc-paginator-range-actions {
              button {
                left: 80.8%;

                &.mat-mdc-paginator-navigation-next {
                  left: 89.5%;
                }

                &.mat-mdc-paginator-navigation-first {
                  left: 78%;
                }

                &.mat-mdc-paginator-navigation-last {
                  left: 92.5%;
                }
              }
            }
          }
        }
      }

      .entire-mat-page-btn {
        .gotojump {
          left: 84.5%;
        }
      }
    }
  }

  .action-icons {
    display: flex;
    gap: 20px;
    width: 50%;
  }

  ruf-dialog-content {
    .botm-btns {
      padding-top: 20px;
    }

    form {
      margin-top: 15px;

      .grid-3-col {
        display: grid;
        grid-template-columns: 31% 31% 31%;
        justify-content: space-between;
        gap: 20px;

        .mat-mdc-form-field {
          .mat-form-field-wrapper {
            padding: 0 !important;
          }
        }
      }
    }

    @media screen and (max-width: 767px) {
      form {
        .grid-3-col {
          grid-template-columns: 1fr;
        }
      }
    }
  }
  // ******************************** Mat Checkbox Styling ******************************

  .mat-checkbox {
    .mat-checkbox-background {
      .mat-checkbox-checkmark {
        fill: #000;

        .mat-checkbox-checkmark-path {
          stroke: #000 !important;
        }
      }
    }
  }
  // ******************************** Admin Dialog Panel ******************************

  .admin-dialog-panel .ruf-dialog-header-bar button .mat-icon {
    font-size: 24px !important;
    color: #012834 !important;
  }
}
@media only screen and (min-width: 767px) and (max-width: 1023px) {
    cp-main-user-Onboarding-page .table-pagination .entire-mat-page-btn{
        position: relative;
        justify-content: normal;
        align-items: normal;
        .search-matpaginator-main .mat-mdc-paginator-outer-container .mat-mdc-paginator-container .mat-mdc-paginator-range-actions button.mat-mdc-paginator-navigation-last{left: 59.5%;}
        .mat-mdc-form-field.gotojump{right: 43.5%; top: 0px;}
    }
    user-onboarding-editable-datatable .mat-mdc-table.cp-admin-custom-datatable.ruf-striped-rows .mat-mdc-row .mat-mdc-cell {
      width: 100%;
      display: flex;
      &.mat-column-MoreActions > div{
        width: 100%;
        div{flex: 0 0 10% !important;}
      }
    }

    cp-main-user-Onboarding-page .table-pagination {
      gap: 15px;
      flex-direction: column;
    }
}
