@use '@ruf/shell' as ruf;
//@import 'node_modules/@ruf/theming/styles/theming/theming';
@use 'node_modules/@ruf/material/src/radio/radio.theme';


// a convenience mixin that aggregates all of your component theme mixins
@mixin cp-audit-controls-validation-theme($theme, $cls: '[fisStyle]') {
  $background: map-get($theme, background);
  $foreground: map-get($theme, foreground);
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $emphasis: map-get($theme, emphasis);
  $warn: map-get($theme, warn);
  $theme-colors: (
    primary: $primary,
    accent: $accent,
    warn: $warn
  );

  // TODO(ruf-v512-migration): Signature of this mixin has been changed
// The new signature is: @mixin mat-radio-theme($theme: ruf.get-default-basic-css-vars-theme(), $customizations: (), $cls: [fisStyle]);
// Manual intervention is required to fix the signture
// If you want to learn more about the mixin and its parameters, please visit the "radio's overview" page on the RUF demo site.
//@include ruf.mat-radio-theme($theme); for testing only 23/4/24 AB

  client-portal-cp-audit-controls-validation {
    p {
      color: ruf.ruf-color($primary);
    }
  }
}

@mixin cp-audit-controls-validation-typography($config) {
  client-portal-cp-audit-controls-validation {
    // p {
    //   @include ruf-typography-level-to-styles($config, caption);
    // }
    a{
        font-weight: bold;
      &:hover{
        text-decoration: underline;
      }
    }
    h1 {
      @include ruf.ruf-typography-level-to-styles($config, 'title');
    }
  }
}
