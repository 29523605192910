@use 'sass:map';
@use '@ruf/shell' as ruf;
// Importing the RUF shell structural styles
/* TODO: You should try using, @use '@ruf/shell/styles/index'; */
@use '@ruf/shell/styles/index';
// import your application's theme mixins
@use '@angular/material' as mat;
@use './_all-theme' as all-theme;

// Define a color theme.
// ruf-quick-palette is useful to create a palette from a single color
// ruf-quick-palette( base, lighter, darker )
$primary: ruf.ruf-quick-palette-from-css-var(primary, #0d2733);
$accent: ruf.ruf-quick-palette-from-css-var(accent, #71c954);
$emphasis: ruf.ruf-quick-palette-from-css-var(emphasis, #6acdec);
$warn: ruf.ruf-quick-palette-from-css-var(warn, #fd8d62);
$success: ruf.ruf-quick-palette-from-css-var(success, #4bcd3e);
$error: ruf.ruf-quick-palette-from-css-var(error, #ff1f3e);
$info: ruf.ruf-quick-palette-from-css-var(info, #3bcff0); 

$foreground: ruf.ruf-get-default-light-theme-foreground-config();
$background: ruf.ruf-get-default-light-theme-background-config();

$_default-density: 0;
$theme: ruf.ruf-light-theme( ( color: ( primary: $primary, accent: $accent, emphasis: $emphasis, warn: $warn, foreground: $foreground, background: $background, success: $success, error: $error, info: $info, ), typography: ruf.ruf-get-default-typography-config(), density: $_default-density, ) );

mat.$theme-ignore-duplication-warnings: true;
// Start: Custom configuration
// These configurations can be used to override exisitng configurations.
// Please check overview section https://infinity.fisdev.local/RUF/ruf-demo/ruf/visual/shell/theme/overview
// to customize the configuration
$typography-config: map.get($theme, typography);
// this is component based configuration for material components where default typography config does not work
$overridden-material-typography-config: ruf.ruf-get-default-material-typography-config( );
// this is component based configuration for shell components where default typography config does not work
$overridden-shell-typography-config: ruf.ruf-get-default-shell-typography-config( );

$material-structural-config: ruf.ruf-get-default-material-structural-config();
$shell-structural-config: ruf.ruf-get-default-shell-structural-config();

$material-theme-config: ruf.ruf-get-default-material-theme-config($theme);
$shell-theme-config: ruf.ruf-get-default-shell-theme-config($theme);
// End: Custom configuration

// Include material typography styles for all components

// Include all RUF color theme styles for the components.
@include ruf.ruf-theme( $theme, $typography-config, $shell-theme-config, $overridden-typography-config: $overridden-shell-typography-config );

// Here you either include each mixin for material 2 components
// that you have imported above or just include 'angular-material-theme($theme)'
// if you imported material 2's all-theme file
// @include mat-sidenav-theme($theme);
// @include mat-radio-theme($theme);

// Include @ruf/material theme and structure mixins

// Include mixin to override structural styling.
@include ruf.ruf-shell-override( $structural-config: $shell-structural-config, $density: $_default-density );

// MDC Components Styles
@include ruf.material-all-component-styles($theme);

@include all-theme.app-all-theme($theme);

@mixin app-all-theme($theme, $config: null) {
  
}
/*.mat-mdc-card {
  padding: 30px;
}
*/
