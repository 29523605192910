//@import 'node_modules/@ruf/theming/styles/theming/theming';
@use "@ruf/shell" as ruf;

// a convenience mixin that aggregates all of your component theme mixins
@mixin cp-create-edit-report-admin-card-theme($theme) {
  $background: map-get($theme, background);
  $foreground: map-get($theme, foreground);
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $emphasis: map-get($theme, emphasis);
  $warn: map-get($theme, warn);

  client-portal-cp-create-edit-report-admin-card {
    p {
      color: ruf.ruf-color($primary);
      line-height: 32px !important;
    }
  }
}

@mixin cp-create-edit-report-admin-card-typography($config) {
  client-portal-cp-create-edit-report-admin-card {
    p {
      // @include ruf-typography-level-to-styles($config, 'title');
    }
  }
}
