//@import 'node_modules/@ruf/theming/styles/theming/theming';

// a convenience mixin that aggregates all of your component theme mixins
@use "@ruf/shell" as ruf;

@mixin my-products-panel-theme($theme) {
  $background: map-get($theme, background);
  $foreground: map-get($theme, foreground);
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $emphasis: map-get($theme, emphasis);
  $warn: map-get($theme, warn);

  client-portal-my-products-panel {
    // p {
    //   color: ruf-color($primary);
    // }
    // .ruf-menu-item {
    //   min-height: 50px;
    // }
    mat-tab-header .mdc-tab__text-label {
      color: #fff !important;
    }

    .mat-form-field-appearance-outline .mat-mdc-form-text-infix {
      padding: 0;
    }

    .mat-mdc-form-text-infix {
      padding-top: 12px;
    }
    //ruf upgrade class changed to mat-mdc-text-field-wrapper
    // .mat-mdc-text-field-wrapper {
    //   background: #fff;
    //   height: 36px;
    //   border-radius: 5px;
    //   padding: 0px 0px !important;
    // }
    .mat-mdc-text-field-wrapper {
      background: #fff !important;
      //height: 36px !important;
      border-radius: 5px !important;
      padding: 0px 0px !important;
      margin: 0.25em 0;
      /*.mat-mdc-form-field-flex{padding: 5px 0;}*/
    }
    .mat-mdc-input-element {
      font:400 16px / 1.125 "Source Sans Pro", Arial, sans-serif !important;
      font-size: 14px !important;
    }

    .mat-mdc-form-text-infix {
      font:400 16px / 1.125 "Source Sans Pro", Arial, sans-serif;
      padding-top: 12px !important;
    }
    .mat-form-field-appearance-outline .mdc-notched-outline {
      border-radius: 5px;
    }

    .select-letter {
      margin-bottom: 15px;
      margin-top: 15px;
    }

    .index {
      display: -ms-flexbox;
      display: flex;
      -ms-flex-direction: row;
      flex-direction: row;
      -ms-flex-pack: distribute;
      justify-content: space-around;
    }

    .index a {
      color: #3bcff0;
      border-bottom-color: transparent;
      position: relative;
      padding: 0 2px;
      font-size: 20px;
    }

    .index a.active:after {
      border-bottom-color: #71c954;
      position: absolute;
      content: "";
      left: 0px;
      bottom: -16px;
      width: 0;
      height: 0;
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
      border-bottom: 10px solid #71c954;
    }

    .index a.disabled {
      color: grey;
      cursor: not-allowed;
      pointer-events: none;
    }
    .fav a.disabled {
      color: grey;
      cursor: not-allowed;
      pointer-events: none;
    }
    .product-list {
      background-color: white;
      height: 60vh;
      overflow-y: scroll;
      padding: 15px;
      margin-top: 16px;
    }

    .product-list h3 {
      font-family: "titillium_webblack" !important;
      color: #015b7e;
      font-size: 25px;
      margin: 0 0 10px 0;
      position: relative;
    }

    .product-list h3:after {
      position: absolute;
      content: "";
      color: #d9d9d9;
      font-weight: normal;
      font-family: "Titillium Web";
      width: 394px;
      height: 1px;
      border-bottom: 1px solid #d9d9d9;
      top: 15px;
      left: 27px;
    }

    .product-list span {
      width: 100%;
      color: #012834;
      line-height: 24px;
      font-size: 14px;
      font-weight: bold;
      padding: 0 0 10px 0;
      /* justify-content: stretch; */
      display: -ms-flexbox;
      display: flex;
    }

    .product-list span mat-icon {
      vertical-align: middle;
      margin-right: 5px;
      font-size: 20px;
    }

    .fav a {
      opacity: 0.5;
    }

    .fav a:hover {
      opacity: 1;
    }

    .fav a.favOpacity {
      opacity: 1;
    }
    .index a {
      opacity: 0.5;
    }

    .index a:hover {
      opacity: 1;
    }
    .index a.favOpacity {
      opacity: 1;
    }
    /* Hiding My Favorites Features */
    // .mat-icon.mat-accent,
    // .mat-mdc-tab.ng-star-inserted
    // { display: none }

    span.mdc-tab-indicator__content.mdc-tab-indicator__content--underline {
      border-color: ruf.ruf-color($accent) !important;
    }
    .mat-mdc-tab-header.mat-mdc-tab-header {
      border: none;
    }
  }
}

@mixin my-products-panel-typography($config) {
  client-portal-my-products-panel {
    .index a {
      font-weight: bold;
    }
  }
}
