forgot-entity {
  .cdk-overlay-pane {
    box-sizing: unset;
  }

  .mat-mdc-dialog-container {
    box-sizing: unset !important;
    border: none;
  }

  hr.ruf-dialog-separator {
    margin: 15px 0 0 0 !important;
  }

  ruf-dialog-header {
    /*padding-bottom: 15px;
    border-bottom: 1px solid #B3C1C7;
    margin-bottom: 30px;*/
    width: 100% !important;
  }

  .ruf-dialog-container {
    padding: 20px;

    .mat-mdc-dialog-title {
      padding: 0px !important;
    }
  }

  ruf-dialog-header .mat-mdc-icon-button {
    padding: 8px 0 0 0 !important;
  }

  .mat-mdc-form-field {
    width: 100%;
    margin-top: 5px;
    padding-bottom: 10px;

    .mat-mdc-text-field-wrapper {
      margin-top: 20px;
    }
  }

  input {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 0.5px solid #B3C1C7;
    border-radius: 5px;
    padding: 5px 10px;
    /*margin-bottom: 15px;
    margin-top: 30px;*/
    width: 50%;
    line-height: 32px;
    font-size: 16px !important;
    font-weight: inherit;
    color: #012834;

    &:focus {
      border: 1px solid #B3C1C7;
    }
  }

  input.ng-pristine.ng-invalid.ng-touched {
    border: 1px solid #de1b36;
  }

  input.hasError {
    border: 1px solid #de1b36;
  }

  ruf-dialog-content p {
    margin-bottom: 0;
  }

  .mat-mdc-button-base {
    margin-right: 8px;
  }

  ::ng-deep .ruf-dialog-container .ruf-dialog-header.mat-mdc-dialog-title .ruf-dialog-header-bar .mat-mdc-icon-button .mat-icon {
    color: #4BCD3E !important;
  }

  p {
    color: #012834;
    font-size: 14px;
    line-height: normal;
  }

  ::ng-deep .mat-mdc-form-text-infix {
    padding: 12px 0 !important;
  }

  ul {
    padding: 0 2.5rem 0 2.5rem;
    color: #012834;
    line-height: normal;
  }

  li {
    font-size: 14px;
    font-weight: 600;
    color: #012834;
  }

  .form {
    margin-left: auto;
    margin-top: 0;
    width: 100%;
  }

  .foot {
    padding: 20px 0;
    border-top: 1px solid #B3C1C7;
    margin-top: 30px;
    .mdc-button.mdc-button--outlined.mat-accent mat-icon.mat-icon{order: 1;}
  }

  .mat-mdc-outlined-button {
    border-color: transparent !important;
    background-color: transparent !important;

    mat-icon {
      top: 1px;
      position: relative;
    }
  }

  .description {
    /*margin-left: 7%;
    margin-bottom: 15px;*/
    width: 100%;
  }
}


@media only screen and (max-width: 768px) {
.form, .description,.foot{
  padding-left:0;
  // margin-left:0
}
    .cdk-overlay-pane mat-dialog-container.mat-mdc-dialog-container .ruf-dialog-panel.fis-override .ruf-dialog-container {
        padding: 1rem 0 1rem 0 !important;
    }
}
.mat-mdc-form-field-error {
    font-size: 16px !important;
    color: #de1b36 !important;
}
