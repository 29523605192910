@use "@ruf/shell" as ruf;
//@import 'node_modules/@ruf/theming/styles/theming/theming';

// a convenience mixin that aggregates all of your component theme mixins
@mixin side-menu-theme($theme) {
  $background: map-get($theme, background);
  $foreground: map-get($theme, foreground);
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $emphasis: map-get($theme, emphasis);
  $warn: map-get($theme, warn);

  client-portal-side-menu {
    .menu-panel {
      background-color: ruf.ruf-color($primary, 600);
    }

    .menu {
      .ruf-menu-item {
        min-height: 40px !important;
        border-top: 1px solid ruf.ruf-color($primary, 300) !important;
        font-size: 14px !important;
      }

      .links {
        .ruf-menu-item {
          min-height: 35px;
          border-top-width: 0 !important;
          font-size: 10px !important;
        }
      }
    }

    .links,
    .separator {
      background-color: ruf.ruf-color($emphasis, 900);
      color: ruf.ruf-color($emphasis);
    }
  }
}

@mixin side-menu-typography($config) {
  client-portal-side-menu {
  }
}
